import { ApplicationType } from "@/types";
import { fetchAPI } from "@/utils/fetchAPI";

type FetchLogin = { email: string; password: string; realmApp: any; application: ApplicationType };

export async function fetchLogin({ email, password, realmApp, application }: FetchLogin) {
  try {
    const res = await fetchAPI({
      url: `/${application}/auth/login`,
      options: {
        method: "POST",
        body: JSON.stringify({ email, password }),
      },
      realmApp,
      application,
    });
    console.log(" res:", res);
    return res;
  } catch (error) {
    throw new Error();
  }
}
