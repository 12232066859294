import { fetchAPI } from "@/utils/fetchAPI";

type FetchRefreshAccessToken = { refreshToken: string };

export async function fetchRefreshAccessTokenTaxi({ refreshToken }: FetchRefreshAccessToken) {
  try {
    return fetchAPI({
      url: "/taxi/refreshToken",
      options: {
        method: "POST",
        body: JSON.stringify({ refreshToken }),
      },
      application: "taxi",
    });
  } catch (error) {
    throw new Error();
  }
}
