import { Box, Input, Label } from "theme-ui";
import { useField } from "formik";

export function TextField({ width = "400px", ...props }: any) {
  const [field] = useField(props);

  if (props?.label) {
    return (
      <Box style={{ width }}>
        <Label>{props?.label}</Label>
        <Input style={{ height: 28, marginTop: 3, borderColor: "#c7c7c7", color: "#333333" }} {...field} {...props} />
      </Box>
    );
  }
  return <Input style={{ height: 28, marginTop: 3, borderColor: "#c7c7c7", color: "#333333" }} {...field} {...props} />;
}
