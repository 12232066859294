import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { PageError } from "./common/PageError";
import { PrivateRoute } from "./common/PrivateRoute";
import { Root } from "@/routes";
import { Welcome } from "@/routes/Welcome";

// Nurse specific imports

import HomePageNurse from "@/routes/nurse/home";
import BillingPageNurse from "@/routes/nurse/billing";
import OfficeListNurse from "@/routes/nurse/offices";
import OfficeIdNurse from "@/routes/nurse/offices/$officeId";
import AgendaNurse from "@/routes/nurse/offices/$officeId/agenda";
import ActivityNurse from "@/routes/nurse/offices/$officeId/activity";
import PatientIdNurse from "@/routes/nurse/offices/$officeId/agenda/$patientId";
import { LoginNurse} from "@/routes/nurse/auth/login";
import { ForgotPasswordNurse } from "@/routes/nurse/auth/password-forgotten";
import { RootNurse } from "@/routes/nurse";

// Taxi specific imports
import HomePageTaxi from "@/routes/taxi/home";
import BillingPageTaxi from "@/routes/taxi/billing";
import { LoginTaxi } from "@/routes/taxi/auth/login";
import { ForgotPasswordTaxi } from "@/routes/taxi/auth/password-forgotten";
import { RootTaxi } from "@/routes/taxi";
import { ApplicationType } from "@/types";

export function AppRouter({ application }: { application?: ApplicationType }) {
  const commonRoutes = [{ path: "/welcome", element: <Welcome /> }];

  const nurseRoutes = [
    {
      path: "/nurse",
      element: <RootNurse />,
      children: [
        { path: "login", element: <LoginNurse /> },
        { path: "forgot-password", element: <ForgotPasswordNurse /> },
        { path: "offices", element: <PrivateRoute Component={OfficeListNurse} /> },
        { path: "home", element: <PrivateRoute Component={HomePageNurse} /> },
        {
          path: "offices/:officeId",
          element: <PrivateRoute Component={OfficeIdNurse} />,
          children: [
            {
              path: "agenda",
              element: <PrivateRoute Component={AgendaNurse} />,
              children: [
                {
                  path: ":patientId",
                  element: <PrivateRoute Component={PatientIdNurse} />,
                },
              ],
            },
            {
              path: "activity",
              element: <PrivateRoute Component={ActivityNurse} />,
            },
          ],
        },
        { path: "billing", element: <PrivateRoute Component={BillingPageNurse} /> },
      ],
    },
  ];

  const taxiRoutes = [
    {
      path: "/taxi",
      element: <RootTaxi />,
      children: [
        { path: "login", element: <LoginTaxi /> },
        { path: "forgot-password", element: <ForgotPasswordTaxi /> },
        { path: "home", element: <PrivateRoute Component={HomePageTaxi} /> },
        { path: "billing", element: <PrivateRoute Component={BillingPageTaxi} /> },
      ],
    },
  ];

  let children = [...commonRoutes];

  if (application === "taxi") {
    children = [...commonRoutes, ...taxiRoutes];
  }
  if (application === "mobility" || application === "inovidel") {
    children = [...commonRoutes, ...nurseRoutes];
  }

  const routes = [
    {
      path: "/",
      element: <Root />,
      errorElement: <PageError />,
      children,
    },
  ];
  console.log("=>  routes:", routes);

  const BrowserRouter = createBrowserRouter(routes);

  return <RouterProvider router={BrowserRouter} />;
}
