import { ApplicationType } from "@/types";
import { getLocalStorageApplication, setLocalStorageApplication } from "@/utils/localStorage";
import React, { createContext, useContext, useEffect, useState } from "react";

type ApplicationContextType = {
  application: ApplicationType;
  setApplication: (application: ApplicationType) => void;
  dbName: string;
};

const ApplicationContext = createContext<ApplicationContextType | undefined>(undefined);

export const ApplicationProvider: React.FC = ({ children }) => {
  const app = getLocalStorageApplication();
  const [application, set] = useState<ApplicationType>(app);
  const [dbName, setDbName] = useState<string>(() => {
    switch (app) {
      case "inovidel":
        return process.env.REACT_APP_DB_NAME_INOVIDEL || '';
      case "mobility":
        return process.env.REACT_APP_DB_NAME_MOBILITY || '';
      case "taxi":
        return process.env.REACT_APP_DB_NAME_TAXI || '';
      default:
        return '';
    }
  });  

  function setApplication(app: ApplicationType) {
    setLocalStorageApplication(app);
    set(app);
  }

  useEffect(() => {
    const app = getLocalStorageApplication();
    set(app);
    console.log("=>  app:", app);
  }, []);

  useEffect(() => {
    switch (application) {
      case "inovidel":
        setDbName(process.env.REACT_APP_DB_NAME_INOVIDEL || '');
        break;
      case "mobility":
        setDbName(process.env.REACT_APP_DB_NAME_MOBILITY || '');
        break;
      case "taxi":
        setDbName(process.env.REACT_APP_DB_NAME_TAXI || '');
        break;
      default:
        setDbName('');
    }
  }, [application]);

  return <ApplicationContext.Provider value={{ application, setApplication, dbName }}>{children}</ApplicationContext.Provider>;
};

export function useApplicationContext() {
  const context = useContext(ApplicationContext);
  if (!context) throw new Error("useApplicationContext must be used within a ApplicationProvider");
  return context;
}
