import styled from "styled-components";
import { useThemeUI } from "theme-ui";

const Link = styled.a`
  background-color: ${({ theme }) => theme.colors.primary};
  border: 2px solid ${({ theme }) => theme.colors.primary};
  color: #fff;
  &:hover {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export function Href({
  textconfirm,
  withconfirm,
  onClick,
  children,
  href,
  target,
  ...props
}: {
  textconfirm?: string;
  withconfirm?: boolean;
  onClick?: any;
  children: any;
  href: any;
  target: any;
}) {
  const { theme } = useThemeUI();
  return (
    <Link {...props} href={href} target={target} className="cofidocHref" theme={theme}>
      {children}
    </Link>
  );
}
