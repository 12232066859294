import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { Patient, UserWithRoleInfo } from "@/types";
import { Box, Button, Text, TextAreaField, TextField } from "@/ui";
import { Modal } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { Divider } from "@material-ui/core";
import { CheckboxField } from "@/ui/field/CheckboxField";
import { SelectField } from "@/ui/field/SelectField";
import ErrorText from "@/ui/ErrorText";
import SettingsIcon from "@mui/icons-material/Settings";
import { useThemeUI } from "theme-ui";
import { useQueryParams } from "@/utils/useQueryParams";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PhoneIcon from "@mui/icons-material/Phone";
import InfoIcon from "@mui/icons-material/Info";
import { PRISE_DE_SANG } from "@/utils/cotationsMapping";
import toast from "react-hot-toast";
import { useAppContext } from "@/app/common/provider/AppProvider";
import qs from "query-string";
import { useNavigate } from "react-router-dom";
import { useOfficeContext } from "@/app/common/provider/OfficeProvider";
import { fetchSendDocument } from "@/api/nurse/fetchSendDocument";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";

export function ModalConfirmDone({
  onConfirm,
  buttonText,
  praticiens,
  patient,
  updateType,
}: {
  onConfirm: (selectedPraticien) => void;
  buttonText: string;
  praticiens: (UserWithRoleInfo & { color: string })[];
  patient: Patient;
  updateType: "updateOneDone" | "updateManyDone" | "updateOne" | "updateMany";
}) {
  const [open, setOpen] = useState(false);
  const [relocations, setRelocations] = useState<any[]>();
  const { client, app } = useMongoContext();
  const { theme } = useThemeUI();
  const currentUser = app?.currentUser?.customData;
  const { dbName } = useApplicationContext();

  useEffect(() => {
    if (client) {
      client
        .db(dbName)
        .collection("Relocations")
        .find()
        .then((res) => {
          if (res) {
            setRelocations([{ value: { _id: "office", label: "Cabinet" }, label: "Cabinet" }, ...res.map((p) => ({ value: p, label: p.label }))]);
          }
        });
    }
  }, [client, dbName]);

  const formatOptionLabel = ({ value, label }) => (
    <div style={{ display: "flex" }}>
      <Box alignItems="center">
        {value?.color && (
          <PersonIcon
            style={{
              color: value?.color,
              fontSize: 18,
              margin: 2,
              marginRight: 5,
            }}
          />
        )}
        <div>{label}</div>
      </Box>
    </div>
  );

  const billingMode = [
    { label: "Dégradé", value: "degrade" },
    { label: "TLA", value: "tla" },
  ];

  function validate(values) {
    const errors: any = {};
    if (["IK", "IKM", "IKS"].includes(values?.relocation?.label) && !values?.kmSupp) {
      errors.kmSupp = "Champs requis";
    }
    if (!values?.relocation) {
      errors.relocation = "Champs requis";
    }
    return errors;
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <Text>{buttonText}</Text>
      </Button>
      <Modal
        open={open}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          style={{
            background: "#fff",
            borderRadius: "5px",
            boxShadow: "rgb(86 86 86) 0px 0px 5px 0px",
          }}
          height="50%"
          width="50%"
          maxWidth="800px"
          minHeight="600px"
        >
          <Formik
            initialValues={{
              praticien: {
                value: praticiens[0],
                label: `${praticiens[0]?.userFirstName} ${praticiens[0]?.userLastName}`,
              },
              billingMode: billingMode?.find((b) => b.value === patient?.billingMode || billingMode[0]),
              relocation: relocations?.find((b) => b.label === patient?.relocation?.label || relocations[0]),
              kmSupp: patient?.kmSupp,
              mci: patient?.mci,
              mie: patient.mie,
              mau: false,
              nightRate: false,
              replacing: null,
            }}
            validate={validate}
            onSubmit={(values) => {
              setOpen(false);
              onConfirm(values);
            }}
          >
            {({ isValid, values, errors, setFieldValue }) => {
              if (!["IK", "IKM", "IKS"].includes(values?.relocation?.label) && values?.kmSupp) {
                setFieldValue("kmSupp", undefined);
              }
              if (values?.praticien?.value?.role === "owner" && values?.replacing?.value) {
                setFieldValue("replacing", undefined);
              }
              const defaultReplace = praticiens.find((p) => p.userId === currentUser?._id);
              if (values?.praticien?.value?.role === "substitute" && !values?.replacing) {
                setFieldValue("replacing", defaultReplace);
              }
              return (
                <Form
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    zIndex: 1,
                  }}
                >
                  <Box
                    height="50px"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="row"
                    fontSize={22}
                    width="80%"
                    p={2}
                    textAlign="center"
                  >
                    <SettingsIcon
                      style={{
                        fontSize: 25,
                        //@ts-ignore
                        color: theme.colors.primary,
                        marginRight: 5,
                      }}
                    />
                    {(updateType === "updateMany" || updateType === "updateManyDone") && "Informations sur les séances à valider"}
                    {(updateType === "updateOne" || updateType === "updateOneDone") && "Informations sur la séance à valider"}
                  </Box>
                  <Divider style={{ width: "100%" }} />
                  <Box flexDirection="column" p={2} height="calc(100% - 50px)" width="100%">
                    {/* COLLABORATOR */}
                    <Box alignItems="center" p={1} height="50px">
                      <Text mr={2}>Collaborateur : </Text>
                      <Box minWidth="250px">
                        <Field
                          name="praticien"
                          component={SelectField}
                          isSearchable={false}
                          formatOptionLabel={formatOptionLabel}
                          options={praticiens?.map((p) => ({
                            value: p,
                            label: `${p.userFirstName} ${p.userLastName}`,
                          }))}
                          styles={{
                            container: (style) => ({ ...style, width: "100%" }),
                            control: (style) => ({
                              ...style,
                              borderColor: "#c7c7c7",
                              height: "28px",
                              maxHeight: "28px",
                              minHeight: "28px",
                              borderRadius: "5px",
                              fontSize: 13,
                              fontWeight: 600,
                            }),
                            indicatorsContainer: (style) => ({
                              ...style,
                              padding: 0,
                            }),
                            valueContainer: (style) => ({
                              ...style,
                              padding: 0,
                            }),
                            option: (style, { isSelected }) => ({
                              ...style,
                              background: isSelected ? "#f72585" : "transparent",
                              padding: "3px 4px",
                              color: isSelected ? "#fff" : "",
                              cursor: "pointer",
                            }),
                            menuList: (style) => ({ ...style, maxHeight: 314 }),
                            singleValue: () => ({
                              paddingLeft: 5,
                              color: "#454545",
                            }),
                          }}
                        />
                      </Box>
                    </Box>
                    {/* REPLACING */}
                    {values?.praticien?.value?.role === "substitute" && (
                      <Box alignItems="center" p={1} height="50px">
                        <Text mr={2}>Remplaçant de : </Text>
                        <Box minWidth="250px">
                          <Field
                            name="replacing"
                            component={SelectField}
                            isSearchable={false}
                            formatOptionLabel={formatOptionLabel}
                            options={praticiens
                              ?.filter((p) => p.role === "owner")
                              ?.map((p) => ({
                                value: p,
                                label: `${p.userFirstName} ${p.userLastName}`,
                              }))}
                            styles={{
                              container: (style) => ({
                                ...style,
                                width: "100%",
                              }),
                              control: (style) => ({
                                ...style,
                                borderColor: "#c7c7c7",
                                height: "28px",
                                maxHeight: "28px",
                                minHeight: "28px",
                                borderRadius: "5px",
                                fontSize: 13,
                                fontWeight: 600,
                              }),
                              indicatorsContainer: (style) => ({
                                ...style,
                                padding: 0,
                              }),
                              valueContainer: (style) => ({
                                ...style,
                                padding: 0,
                              }),
                              option: (style, { isSelected }) => ({
                                ...style,
                                background: isSelected ? "#f72585" : "transparent",
                                padding: "3px 4px",
                                color: isSelected ? "#fff" : "",
                                cursor: "pointer",
                              }),
                              menuList: (style) => ({
                                ...style,
                                maxHeight: 314,
                              }),
                              singleValue: () => ({
                                paddingLeft: 5,
                                color: "#454545",
                              }),
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                    {/* MIE MAU MCI */}
                    {(updateType === "updateManyDone" || updateType === "updateOneDone") && (
                      <Box alignItems="center" p={1} height="50px">
                        <Box alignItems="center" justifyContent="center" mr={1}>
                          <Text>MIE : </Text>
                          <Field component={CheckboxField} name="mie" />
                        </Box>
                        <Box alignItems="center" justifyContent="center" mr={1}>
                          <Text>MAU : </Text>
                          <Field component={CheckboxField} name="mau" />
                        </Box>
                        <Box alignItems="center" justifyContent="center" mr={1}>
                          <Text>MCI : </Text>
                          <Field component={CheckboxField} name="mci" />
                        </Box>
                      </Box>
                    )}
                    {/* RELOCATION */}
                    {(updateType === "updateManyDone" || updateType === "updateOneDone") && (
                      <Box alignItems="center" p={1} height="50px">
                        <Text mr={2}>Déplacement : </Text>
                        <Box minWidth="250px">
                          <Field
                            name="relocation"
                            component={SelectField}
                            isSearchable={false}
                            options={relocations}
                            styles={{
                              container: (style) => ({
                                ...style,
                                width: "100%",
                              }),
                              control: (style) => ({
                                ...style,
                                borderColor: "#c7c7c7",
                                height: "28px",
                                maxHeight: "28px",
                                minHeight: "28px",
                                borderRadius: "5px",
                                fontSize: 13,
                                fontWeight: 600,
                              }),
                              indicatorsContainer: (style) => ({
                                ...style,
                                padding: 0,
                              }),
                              valueContainer: (style) => ({
                                ...style,
                                padding: 0,
                              }),
                              option: (style, { isSelected }) => ({
                                ...style,
                                background: isSelected ? "#f72585" : "transparent",
                                padding: "3px 4px",
                                color: isSelected ? "#fff" : "",
                              }),
                              menuList: (style) => ({
                                ...style,
                                maxHeight: 314,
                              }),
                              singleValue: () => ({
                                paddingLeft: 5,
                                color: "#454545",
                              }),
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                    {/* KMSUPP */}
                    {(updateType === "updateManyDone" || updateType === "updateOneDone") &&
                      !!values?.relocation?.label &&
                      ["IK", "IKM", "IKS"].includes(values?.relocation?.label) && (
                        <Box alignItems="center" p={1} height="50px">
                          <Text mt={1} mr={2}>
                            Kilomètre supplémentaire :
                          </Text>
                          <Box minWidth="250px" flexDirection="column" position="relative">
                            <TextField name="kmSupp" type="number" />
                            {errors?.kmSupp && (
                              <Box position="absolute" bottom={-15}>
                                <ErrorText>{errors?.kmSupp}</ErrorText>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                    {/* BILLING MODE */}
                    {(updateType === "updateManyDone" || updateType === "updateOneDone") && (
                      <Box alignItems="center" p={1} height="50px">
                        <Text mr={2}>Mode de facturation : </Text>
                        <Box minWidth="250px">
                          <Field
                            name="billingMode"
                            component={SelectField}
                            isSearchable={false}
                            options={billingMode}
                            styles={{
                              container: (style) => ({
                                ...style,
                                width: "100%",
                              }),
                              control: (style) => ({
                                ...style,
                                borderColor: "#c7c7c7",
                                height: "28px",
                                maxHeight: "28px",
                                minHeight: "28px",
                                borderRadius: "5px",
                                fontSize: 13,
                                fontWeight: 600,
                              }),
                              indicatorsContainer: (style) => ({
                                ...style,
                                padding: 0,
                              }),
                              valueContainer: (style) => ({
                                ...style,
                                padding: 0,
                              }),
                              option: (style, { isSelected }) => ({
                                ...style,
                                background: isSelected ? "#f72585" : "transparent",
                                padding: "3px 4px",
                                color: isSelected ? "#fff" : "",
                              }),
                              menuList: (style) => ({
                                ...style,
                                maxHeight: 314,
                              }),
                              singleValue: () => ({
                                paddingLeft: 5,
                                color: "#454545",
                              }),
                            }}
                          />
                        </Box>
                      </Box>
                    )}
                    {/* NIGHT RATE */}
                    {(updateType === "updateManyDone" || updateType === "updateOneDone") && (
                      <Box alignItems="center" p={1} height="50px">
                        <Text>Tarif de nuit : </Text>
                        <Field component={CheckboxField} name="nightRate" />
                      </Box>
                    )}
                  </Box>
                  <Divider style={{ width: "100%" }} />
                  <Box height="12%" width="100%" justifyContent="center" alignItems="center">
                    <Button onClick={() => setOpen(false)}>Annuler</Button>
                    <Box p={1} />
                    <Button type="submit" disabled={!isValid}>
                      Valider
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
          <PlaylistAddCheckIcon
            style={{
              fontSize: 400,
              position: "absolute",
              //@ts-ignore
              color: theme.colors.light,
              opacity: 0.15,
            }}
          />
        </Box>
      </Modal>
    </>
  );
}

export function ModalConfirmSend({
  formatedSeancesByPatient,
  praticiens,
  selectedPatientIds,
  setSelectedPatientIds,
}: {
  formatedSeancesByPatient: any;
  praticiens: any;
  selectedPatientIds: any;
  setSelectedPatientIds: any;
}) {
  const [open, setOpen] = useState(false);
  const [filteredSeances, setFilteredSeances] = useState([]);
  const { app } = useMongoContext();
  const { theme } = useThemeUI();
  const params = useQueryParams();
  const { setLoading } = useAppContext();
  const { currentOfficeInfo } = useOfficeContext();
  const currentUser = app?.currentUser?.customData;
  const clientType = currentUser?.clientType;
  const navigate = useNavigate();
  const { application } = useApplicationContext();

  const currentPraticien = praticiens?.find((p) => p.userId === params?.collaborator);

  useEffect(() => {
    let isTrial = clientType === "trial";
    const filteredSeances = formatedSeancesByPatient.flatMap(({ seances }) =>
      seances?.filter((seance) => {
        const hasInvalidPrescription = seance.cares.some(care => {
          return (!care.prescription?.media?._id || !care.prescription?.prescriptionDate) && !care.prescription?.empty;
        });

        if (isTrial)
          return (
            seance.status === "done" &&
            seance?.cares?.find((care) => care?.cofidocCareId === PRISE_DE_SANG) &&
            selectedPatientIds?.includes(seance.patient) && !hasInvalidPrescription
          );

        return seance.status === "done" && selectedPatientIds?.includes(seance.patient) && !hasInvalidPrescription;
      })
    );
    setFilteredSeances(filteredSeances);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatedSeancesByPatient, selectedPatientIds, clientType]);

  async function updateAllSeanceToReadyToBill(comment: any, seancesIds) {
    setLoading(true);

    try {
      await fetchSendDocument({
        version: "web 0.1.0",
        seanceIds: seancesIds,
        officeId: currentOfficeInfo?.officeId,
        comment: comment || "",
        realmApp: app,
        application,
      });
      let to = qs.stringify({ ...params }, { arrayFormat: "index" });
      navigate(`/nurse/offices/${currentOfficeInfo?.officeId}/activity?${to}`, {
        replace: true,
      });
      navigate(0);
      toast.success("Séance mise à jour");
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      toast.error("Erreur lors de l'envoi des séances");
    } finally {
      setLoading(false);
      setOpen(false);
      setSelectedPatientIds([]);
    }
  }

  async function handleConfirm({ comment }) {
    if (clientType === "trial") {
      updateAllSeanceToReadyToBill(
        "",
        filteredSeances?.map((seance) => seance?._id)
      );
    }
    if (clientType === "cofidoc") {
      updateAllSeanceToReadyToBill(
        comment,
        filteredSeances?.map((seance) => seance?._id)
      );
    }
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <Text>Envoyer la période à Cofidoc</Text>
      </Button>
      <Modal
        open={open}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          style={{
            background: "#fff",
            borderRadius: "5px",
            boxShadow: "rgb(86 86 86) 0px 0px 5px 0px",
          }}
          height="50%"
          width="50%"
          maxWidth="800px"
          minHeight="600px"
          position="relative"
        >
          {clientType === "not_client" && <RenderNotClient setOpen={setOpen} />}
          {clientType === "trial" && (
            <RenderTrial
              currentUser={currentUser}
              setOpen={setOpen}
              onConfirm={() => handleConfirm({ comment: "" })}
              params={params}
              currentPraticien={currentPraticien}
              filteredSeances={filteredSeances}
            />
          )}
          {clientType === "cofidoc" && (
            <RenderCofidoc
              currentUser={currentUser}
              setOpen={setOpen}
              onConfirm={(comment) => handleConfirm(comment)}
              params={params}
              currentPraticien={currentPraticien}
            />
          )}
          <ForwardToInboxIcon
            style={{
              fontSize: 350,
              position: "absolute",
              //@ts-ignore
              color: theme.colors.light,
              opacity: 0.15,
            }}
          />
        </Box>
      </Modal>
    </>
  );
}

const RenderNotClient = ({ setOpen }: { setOpen: any }) => {
  const { theme } = useThemeUI();
  return (
    <Box
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        zIndex: 1,
      }}
    >
      <Box height="50px" justifyContent="center" alignItems="center" flexDirection="row" fontSize={22} width="80%" p={2} textAlign="center">
        {/* @ts-ignore */}
        <InfoIcon style={{ fontSize: 25, color: theme.colors.primary, marginRight: 5 }} />
        Informations
      </Box>
      <Divider style={{ width: "100%" }} />
      <Box flexDirection="column" p={2} height="calc(100% - 50px)" width="100%" justifyContent="center" alignItems="center">
        <Box width="80%" p={2}>
          <Text fontSize={20} fontWeight="400" textAlign="center">
            Votre période d'essai est terminée. Si vous souhaitez utiliser le service de facturation, contactez Cofidoc.
          </Text>
        </Box>

        <Box
          mb={1}
          p={1}
          border="1px solid #6CE9AE50"
          bgcolor="#6CE9AE30"
          width="80%"
          borderRadius={5}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text fontWeight="400" textAlign="center">
            Vous souhaitez envoyer l'intégralité de votre activité ? Contactez Cofidoc au
          </Text>
          <Box flexDirection="row" alignItems="center">
            <Box bgcolor="#fff" width={20} height={20} borderRadius={50} mr={1}>
              <Box
                width={20}
                height={20}
                borderRadius={50}
                bgcolor={`${theme.colors.pink}10`}
                border={`1px solid ${theme.colors.pink}`}
                justifyContent="center"
                alignItems="center"
              >
                <PhoneIcon style={{ fontSize: 14, color: theme.colors.pink as any }} />
              </Box>
            </Box>
            <Text fontWeight="600" fontSize={16} textAlign="center" color={theme.colors.pink}>
              0 806 08 08 08
            </Text>
          </Box>
        </Box>
      </Box>
      <Divider style={{ width: "100%" }} />
      <Box height="12%" width="100%" justifyContent="center" alignItems="center">
        <Button onClick={() => setOpen(false)}>Fermer</Button>
      </Box>
    </Box>
  );
};

const RenderTrial = ({
  currentUser,
  setOpen,
  onConfirm,
  params,
  currentPraticien,
  filteredSeances,
}: {
  currentUser: any;
  setOpen: any;
  onConfirm: any;
  params: any;
  currentPraticien: any;
  filteredSeances: any;
}) => {
  const { theme } = useThemeUI();

  return (
    <Box
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
        zIndex: 1,
      }}
    >
      <Box height="50px" justifyContent="center" alignItems="center" flexDirection="row" fontSize={22} width="80%" p={2} textAlign="center">
        {/* @ts-ignore */}
        <InfoIcon style={{ fontSize: 25, color: theme.colors.primary, marginRight: 5 }} />
        Informations
      </Box>
      <Divider style={{ width: "100%" }} />
      <Box flexDirection="column" p={2} height="calc(100% - 50px)" width="100%" justifyContent="center" alignItems="center">
        <Text pb={2}>
          Avant d'envoyer vos actes en facturation voici quelques&nbsp;
          <Text color={theme.colors.primary} fontWeight="600">
            informations importantes
          </Text>
          .
        </Text>
        <Text pb={2}>
          La période d'essai vous permet d'envoyer en facturation uniquement&nbsp;
          <Text color={theme.colors.primary} fontWeight="600">
            des prises de sang
          </Text>
          .
        </Text>
        <Box
          my={1}
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          bgcolor={theme.colors.bgPale}
          border={`1px solid ${theme.colors.primary}`}
          p={2}
          borderRadius={5}
          mb={2}
        >
          <Box bgcolor="#fff" width={20} height={20} borderRadius={50} mr={2}>
            <Box width={20} height={20} borderRadius={50} bgcolor="#df533050" border="1px solid #df5330" justifyContent="center" alignItems="center">
              <Text fontSize={18} fontWeight="bold" color="#df5330">
                +
              </Text>
            </Box>
          </Box>
          <Text fontSize={11} fontWeight="400" width="90%">
            Prise de sang&nbsp;
            <Text color={theme.colors.primary} fontSize={11} fontWeight="400">
              (ami 1.5)
            </Text>
          </Text>
        </Box>
        <Text pb={2}>
          Cette période d'essai vous permet de faire&nbsp;
          <Text color={theme.colors.primary} fontWeight="600">
            1 unique envoi
          </Text>
          &nbsp;de&nbsp;
          <Text color={theme.colors.primary} fontWeight="600">
            15 à 50
          </Text>
          &nbsp;prises de sang.
        </Text>

        <Box
          m={2}
          p={1}
          border="1px solid #6CE9AE50"
          bgcolor="#6CE9AE30"
          width="80%"
          borderRadius={5}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Text fontWeight="400" textAlign="center">
            Vous souhaitez envoyer l'intégralité de votre activité ? Contactez Cofidoc au
          </Text>
          <Box flexDirection="row" alignItems="center">
            <Box bgcolor="#fff" width={20} height={20} borderRadius={50} mr={1}>
              <Box
                width={20}
                height={20}
                borderRadius={50}
                bgcolor={`${theme.colors.pink}10`}
                border={`1px solid ${theme.colors.pink}`}
                justifyContent="center"
                alignItems="center"
              >
                <PhoneIcon style={{ fontSize: 14, color: theme.colors.pink as any }} />
              </Box>
            </Box>
            <Text fontWeight="600" fontSize={16} textAlign="center" color={theme.colors.pink}>
              0 806 08 08 08
            </Text>
          </Box>
        </Box>
        <Box pt={2} pb={4} width="90%" justifyContent="center" alignItems="center">
          {filteredSeances?.length >= 15 && filteredSeances?.length <= 50 && (
            <>
              <Text fontWeight="400" textAlign="center">
                Une fois votre envoi effectué, nous vous contacterons dans les plus bref délai afin de réaliser votre facturation.
              </Text>
              {params?.collaborator !== currentUser?._id && (
                <Box justifyContent="center" alignItems="center">
                  {!params?.collaborator && (
                    <Text fontWeight="600" style={{ textAlign: "center" }} color={theme.colors.red}>
                      Attention, vous êtes sur le point d'envoyer l'activité de tout votre cabinet.
                    </Text>
                  )}
                  {params?.collaborator && (
                    <Text fontWeight="600" style={{ textAlign: "center" }} color={theme.colors.red}>
                      Attention, vous êtes sur le point d'envoyer l'activité de {currentPraticien?.userLastName} {currentPraticien?.userFirstName}.
                    </Text>
                  )}
                </Box>
              )}
            </>
          )}
          {(filteredSeances?.length < 15 || filteredSeances?.length > 50) && (
            <Box flexDirection="column" justifyContent="center" alignItems="center">
              <Text fontWeight="400" textAlign="center" p={1}>
                Vous devez sélectionner une période regroupant entre
                <span style={{ color: theme.colors.red as any, fontWeight: 500 }}>&nbsp;15&nbsp;</span>
                et
                <span style={{ color: theme.colors.red as any, fontWeight: 500 }}>&nbsp;50&nbsp;</span>
                prises de sang.
              </Text>
              <Text textAlign="center" p={1}>
                Votre période regroupe actuellement{" "}
                <span style={{ color: theme.colors.red as any, fontWeight: 500 }}>&nbsp;{filteredSeances?.length}&nbsp;</span>
                prise de sang
              </Text>
            </Box>
          )}
        </Box>
      </Box>
      <Divider style={{ width: "100%" }} />
      <Box height="12%" width="100%" justifyContent="center" alignItems="center">
        <Button onClick={() => setOpen(false)}>Annuler</Button>
        <Box p={1} />
        <Button
          // disabled={
          //   filteredSeances?.length < 15 || filteredSeances?.length > 50
          // }
          onClick={onConfirm}
        >
          Valider
        </Button>
      </Box>
    </Box>
  );
};

const RenderCofidoc = ({
  currentUser,
  setOpen,
  onConfirm,
  params,
  currentPraticien,
}: {
  currentUser: any;
  setOpen: any;
  onConfirm: any;
  params: any;
  currentPraticien: any;
}) => {
  const { theme } = useThemeUI();
  return (
    <Formik
      initialValues={{
        comment: "",
      }}
      onSubmit={(values) => {
        setOpen(false);
        onConfirm(values);
      }}
    >
      {() => {
        return (
          <Form
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              zIndex: 1,
            }}
          >
            <Box height="50px" justifyContent="center" alignItems="center" flexDirection="row" fontSize={22} width="80%" p={2} textAlign="center">
              <InfoIcon
                style={{
                  fontSize: 25,
                  //@ts-ignore
                  color: theme.colors.primary,
                  marginRight: 5,
                }}
              />
              Informations
            </Box>
            <Divider style={{ width: "100%" }} />
            <Box flexDirection="column" p={2} height="calc(100% - 50px)" width="100%" justifyContent="center" alignItems="center">
              <Box pb={4} flexDirection="column" justifyContent="center" alignItems="center">
                <Text style={{ textAlign: "center" }}>Etes-vous sûr de vouloir envoyer la période sélectionnées ?</Text>
              </Box>
              <Box pb={4} flexDirection="column">
                {params?.collaborator !== currentUser?._id && (
                  <Box justifyContent="center" alignItems="center" pb={4}>
                    {!params?.collaborator && (
                      <Text fontWeight="600" style={{ textAlign: "center" }} color={theme.colors.red}>
                        Attention, vous êtes sur le point d'envoyer l'activité de tout votre cabinet.
                      </Text>
                    )}
                    {params?.collaborator && (
                      <Text fontWeight="600" style={{ textAlign: "center" }} color={theme.colors.red}>
                        Attention, vous êtes sur le point d'envoyer l'activité de {currentPraticien?.userLastName} {currentPraticien?.userFirstName}.
                      </Text>
                    )}
                  </Box>
                )}
                <TextAreaField
                  style={{
                    marginTop: 3,
                    bgcolor: "#fff",
                    borderColor: "#c7c7c7",
                    color: "#7e7d7d",
                    minHeight: "50px",
                    maxWidth: "700px",
                    maxHeight: "250px",
                  }}
                  placeholder="..."
                  width="100%"
                  label="Souhaitez-vous laisser un commentaire pour votre gestionnaire ?"
                  name="comment"
                />
              </Box>
            </Box>
            <Divider style={{ width: "100%" }} />
            <Box height="12%" width="100%" justifyContent="center" alignItems="center">
              <Button onClick={() => setOpen(false)}>Annuler</Button>
              <Box p={1} />
              <Button type="submit">Valider</Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
