import { Box } from "./Box";

export function Modal({ children, open }: any) {
  return (
    <>
      {open && (
        <Box
          style={{
            width: "100vw",
            height: "100vh",
            position: "fixed",
            background: "#b2b2b258",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 10,
            top: 0,
            left: 0,
          }}
        >
          <Box
            style={{
              minWidth: "400px",
              minHeight: "250px",
              flexDirection: "column",
              backgroundColor: "white",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 5,
              boxShadow: "0px 0px 17px 0px #ababab",
              overflow: "hidden",
              // padding: "24px",
            }}
          >
            {children}
          </Box>
        </Box>
      )}
    </>
  );
}
