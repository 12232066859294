import { useThemeUI } from "theme-ui";

export function PageLoader() {
  const { theme } = useThemeUI();

  return (
    <div
      id="app-loader"
      style={{
        position: "fixed",
        zIndex: 99999999,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        background: "#9797979b",
        height: "100vh",
        width: "100vw",
        top: 0,
        left: 0,
      }}
    >
      <div className="bar-loader">
        <div className="bar" style={{ backgroundColor: "#3F3F3F" }}></div>
        <div className="bar" style={{ backgroundColor: theme.colors.primary }}></div>
        <div className="bar" style={{ backgroundColor: "#ffffff" }}></div>
        <div className="bar" style={{ backgroundColor: theme.colors.primary }}></div>
        <div className="bar" style={{ backgroundColor: "#3F3F3F" }}></div>
        <div className="bar" style={{ backgroundColor: "#ffffff" }}></div>
      </div>
    </div>
  );
}
