import { getLocalStorageAccessToken, setLocalStorageAccessToken, getRefreshToken, setRefreshToken, setIdToken } from "./localStorage";
import { fetchRefreshAccessTokenNurse } from "@/api/nurse/fetchRefreshAccessToken";
import { fetchRefreshAccessTokenTaxi } from "@/api/taxi/fetchRefreshAccessToken";
import { ApplicationType } from "@/types";

let count = 0;

type FetchProps = {
  url: string;
  options: RequestInit;
  realmApp?: any;
  application?: ApplicationType;
};

export const fetchAPI = async ({ url, options, realmApp, application }: FetchProps) => {
  const token = getLocalStorageAccessToken();
  let baseURL = "";

  if (application === "inovidel") {
    baseURL = process.env.REACT_APP_API_URL_INOVIDEL;
  }
  if (application === "mobility") {
    baseURL = process.env.REACT_APP_API_URL_MOBILITY;
  }
  if (application === "taxi") {
    baseURL = process.env.REACT_APP_API_URL_TAXI;
  }

  const response = await fetch(`${baseURL}${url}`, {
    headers: {
      ...(options.headers || {}),
      "Content-Type": "application/json",
      Accept: "application/json",
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    ...options,
  });
  console.log("=> response:", response);

  if (!response) {
    throw new Error("server_error");
  }

  const parsedResponse = await response.json();

  if (response.status === 400) {
    throw new Error("server_error");
  }
  if (response.status === 401 && (parsedResponse?.message === "The incoming token has expired" || parsedResponse?.message === "Unauthorized")) {
    if (count >= 5) {
      throw new Error("session_expired");
    }

    const refreshToken = getRefreshToken();
    let result = null;

    if (application === "taxi") {
      result = await fetchRefreshAccessTokenTaxi({ refreshToken });
    }
    if (application === "inovidel" || application === "mobility") {
      result = await fetchRefreshAccessTokenNurse({ refreshToken, application });
    }

    if (result?.status === "400" || result?.status === "401") {
      if (realmApp) {
        realmApp?.currentUser?.logOut();
      }
      setLocalStorageAccessToken("");
      setIdToken("");
      window.location.replace("/welcome");
    }
    setLocalStorageAccessToken(result.accessToken);
    setRefreshToken(result.refresh);
    count += 1;
    return fetchAPI({ url, options });
  }

  if (response.status > 206) {
    throw new Error(parsedResponse?.message);
  }

  return parsedResponse;
};
