import styled from "styled-components";
import { useThemeUI } from "theme-ui";
import { Box } from "./Box";

const StyledBox = styled(Box)<{ selected: boolean }>`
  background-color: ${({ theme, selected }) => (selected ? theme.colors.light : "transparent")};
  &:hover {
    background-color: ${({ theme, selected }) => (selected ? theme.colors.light : theme.colors.background)};
  }
  transition: all ease 200ms;
`;

export function NavLink({ onClick, children, selected }: any) {
    const { theme } = useThemeUI();
    return (
      <StyledBox
        onClick={onClick}
        mb={2}
        p="4px"
        pl={1}
        selected={selected}
        bgcolor={selected ? theme.colors.light : "transparent"}
        style={{
          borderRadius: 40,
          width: "90%",
          cursor: "pointer",
        }}
        alignItems="center"
      >
        {children}
      </StyledBox>
    );
  }