import { Box } from "./Box";

export function Card(props) {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        p: 3,
        borderRadius: 10,
        border: "1px solid #e0e0e0",
        // boxShadow: "rgba(0, 0, 0, 0.476) 0px 0px 10px -7px",
      }}
      {...props}
    />
  );
}
