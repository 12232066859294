export const BSI_FORFAIT_ID = [
  'ec883a1e-bbd7-431b-a6ba-44812c0678a2', // BSA
  '8874c567-9ad2-4d0a-956d-3349cda2f044', //BSB
  '5fad57c0-781c-4187-8f6c-fca123fdbcb8', // BSC
];
export const PRISE_DE_SANG = '5ec4d48b-3b96-4a97-9001-cdac2e0c1202';
export const DEXTRO = '9851dd2b-804d-4368-be0c-8c45ff250da0';
export const INSULINE = 'bbef5e17-3ee9-41af-a8f4-306d23509d00';
export const AUTER_PST = 'c880d718-7e41-4e38-a2a1-a6a91780cf6f';
export const IFI_ID = '4853195f-cc99-4dac-8094-30fe42ca4464';
export const IFI_ID_COUPLE = '795a6681-ae58-428e-a9f8-590e46a9e497';
export const IFI_IDS = [IFI_ID, IFI_ID_COUPLE];
export const PSM_L_C = '075e67cf-a9ac-418b-bf81-7415be235830';
export const AUTRES_PST = 'c880d718-7e41-4e38-a2a1-a6a91780cf6f';
export const PSM_L_C_I = '83538b59-c4e7-493f-bf4c-70105e619e9d';
