import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./image.css";
import AppSelector from "./app/AppSelector";
import { ApplicationProvider } from "./app/common/provider/ApplicationContext";

ReactDOM.render(
  <React.StrictMode>
    <ApplicationProvider>
      <AppSelector />
    </ApplicationProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
