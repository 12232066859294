import { Header, LeftNavBar } from "@/app/nurse/AppShell";
import { useAppContext } from "@/app/common/provider/AppProvider";
import { useOfficeContext } from "@/app/common/provider/OfficeProvider";
import { OfficeWithRole } from "@/types";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import { Box, Button, Text } from "@/ui";
import { useNavigate } from "react-router-dom";
import { useThemeUI } from "theme-ui";
import qs from "query-string";
import { useQueryParams } from "@/utils/useQueryParams";

export default function OfficeList() {
  const { setCurrentOffice, userOffices } = useOfficeContext();
  const { setLoading } = useAppContext();
  const { theme } = useThemeUI();
  const navigate = useNavigate();
  const params = useQueryParams();
  const isInovie = process.env.REACT_APP_INOVIE_DEL;

  async function handleSelectOffice(office: OfficeWithRole) {
    try {
      setLoading(true);
      const res = await setCurrentOffice(office);
      console.log("=>  res:", res);
      if (res?.officeId) {
        const start = new Date(new Date().setDate(new Date().getDate() - 31));
        start.setHours(0, 0);
        const end = new Date(new Date().setDate(new Date().getDate()));
        end.setHours(23, 59);

        if (params?.redirect === "agenda") {
          delete params.redirect;
          let to = qs.stringify(
            {
              ...params,
              start: start.getTime(),
              end: end.getTime(),
            },
            { arrayFormat: "index" }
          );
          navigate(`${office?.officeId}/agenda?${to}`);
          navigate(0);
        }
        if (params?.redirect === "agenda") {
          delete params.redirect;
          let to = qs.stringify(
            {
              ...params,
              start: start.getTime(),
              end: end.getTime(),
            },
            { arrayFormat: "index" }
          );
          navigate(`${office?.officeId}/activity?${to}`);
          navigate(0);
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box flexDirection="column" height="100vh" justifyContent="center" alignItems="center" width="100vw">
      {isInovie ? <div className="background-mountain-inovie" /> : <div className="background-mountain-cofidoc" />}
      <div className="background-cloud height40" />
      <div className="background-over" />
      <Header />
      <Box flexDirection="row" width="100%" height="calc(100% - 6vh)" justifyContent="center" alignItems="center">
        <LeftNavBar />
        <Box flexDirection="column" width="calc(100% - 10vw)" height="100%" justifyContent="center" alignItems="center" className="overBg">
          <Box
            flexDirection="column"
            width="60%"
            height="80%"
            bgcolor="#fffffff6"
            style={{
              borderRadius: 5,
            }}
          >
            <Box justifyContent="center" alignItems="center" width="100%" height="80px" position="relative">
              <Box>
                <Text style={{ fontSize: 40, fontWeight: 50 }}>Sélectionner un cabinet</Text>
              </Box>
            </Box>
            <>
              <Box
                flexDirection="row"
                flexWrap="wrap"
                width="100%"
                style={{ overflow: "scroll", height: "calc(100% - 80px)" }}
                className="hiddenScrollBarAndChildren"
                justifyContent="center"
                alignItems="center"
              >
                {userOffices &&
                  userOffices?.map((office) => {
                    return (
                      <Box
                        key={office.officeId}
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        style={{
                          backgroundColor: "#f5f5f5",
                          boxShadow: "rgb(161, 161, 161) 0px 0px 5px -2px",
                          margin: 25,
                          borderRadius: 5,
                          height: "240px",
                          width: "240px",
                          position: "relative",
                        }}
                      >
                        <Box alignItems="center" flexDirection="column" zIndex={1}>
                          <Text fontSize={25} fontWeight={400} color={theme.colors.primary}>
                            {office?.officeName}
                          </Text>
                          <Text fontSize={20} fontWeight={400} color={theme.colors.secondary}>
                            {office?.role === "owner" ? "Titulaire" : "Remplaçant"}
                          </Text>
                        </Box>
                        <Box alignItems="center" justifyContent="center" zIndex={1} p={2}>
                          <Button onClick={() => handleSelectOffice(office)}>Utiliser</Button>
                        </Box>
                        <MapsHomeWorkIcon
                          style={{
                            fontSize: 100,
                            position: "absolute",
                            bottom: 5,
                            right: 5,
                            color: "#ffffff8a",
                            zIndex: 0,
                          }}
                        />
                      </Box>
                    );
                  })}
              </Box>
            </>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
