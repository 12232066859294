import { Box, Text } from "@/ui";
import { useThemeUI } from "theme-ui";
export const LAST_CONNECTION = "LAST_CONNECTION";

const ButtonStore = ({ store, url }) => {
  return (
    <Box justifyContent="center" alignItems="center">
      <a className={store === "ios" ? "downloadAndroid" : "downloadApple"} href={url} target="_blank" rel="noopener noreferrer">
        &nbsp;
      </a>
    </Box>
  );
};
export default function MobileView() {
  const { theme } = useThemeUI();
  const isInovie = process.env.REACT_APP_INOVIE_DEL;
  return (
    <>
      <div className="background-mountain-mobile-cofidoc" />
      <div className="background-cloud height65" />
      <div className="background-over-mobile" />
      <Box width="100vw" height="100vh" justifyContent="space-between" alignItems="center" className="overBg" flexDirection="column">
        <Box display="flex" pt={28} alignItems="center" justifyContent="center" width="100%">
          {isInovie ? <div className="logo-inovie" /> : <div className="logo-cofidoc-black" />}
        </Box>
        <Box width="100%" flexDirection="column">
          <Text style={{ fontSize: 30, fontWeight: 200 }} flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="center">
            Découvrez l'application &nbsp;
            <Text
              style={{
                fontSize: 40,
                fontWeight: 500,

                color: theme.colors.primary,
              }}
            >
              {isInovie ? "Inov'IDEL" : "Cofidoc Mobility"}
            </Text>
          </Text>
          <Box p={5} />
          <Box flexDirection="column" justifyContent="center" alignItems="center">
            <Box width="49%" justifyContent="center" alignItems="center">
              <ButtonStore store="ios" url="https://apps.apple.com/fr/app/cofidoc-mobility/id6462401626" />
            </Box>
            <Box p={2} />
            <Box width="49%" justifyContent="center" alignItems="center">
              <ButtonStore store="android" url="https://play.google.com/store/apps/details?id=com.cofidocmobility" />
            </Box>
          </Box>
        </Box>
        <Box style={{ padding: 5 }}>
          <Text style={{ textAlign: "center", fontSize: 12 }}>© 2024 {!isInovie && "Cofidoc Groupe"}</Text>
        </Box>
      </Box>
    </>
  );
}
