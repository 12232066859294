import { Care, Patient, SeanceWithEntities, UserWithRoleInfo } from "@/types";
import { Box, Text } from "@/ui";
import { uniq } from "lodash";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import ArticleIcon from "@mui/icons-material/Article";
import InfoIcon from "@mui/icons-material/Info";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PixIcon from "@mui/icons-material/Pix";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import { getBillingMode } from "@/components/common/getStatus";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { FaUserNurse } from "react-icons/fa";

export function WrapperInfos({
  infos,
  openWrapper,
  setOpenWrapper,
  index,
  setOpenLateral,
  openLateral,
}: {
  infos: { patient: Patient; seancesWithOtherBillingMode: SeanceWithEntities[]; seancesWithOtherRelocation: SeanceWithEntities[] };
  openWrapper: number;
  setOpenWrapper: any;
  index: number;
  setOpenLateral: any;
  openLateral: boolean;
}) {
  const uniqOtherBillingMode = infos?.seancesWithOtherBillingMode.length
    ? uniq(infos?.seancesWithOtherBillingMode.map((seance) => seance.billingMode))
    : null;
  const uniqOtherRelocation = infos?.seancesWithOtherBillingMode.length
    ? uniq(infos?.seancesWithOtherRelocation.map((seance) => ({ relocation: seance?.relocation?.label, kmSupp: seance.kmSupp })))
    : null;

  return (
    <>
      <Box style={{ justifyContent: "space-between", alignItems: "center" }} width="100%" height={50}>
        <Box
          width={44}
          height="100%"
          justifyContent="center"
          alignItems="center"
          style={{
            cursor: "pointer",
            backgroundColor: "#d0e1ff45",
          }}
          onClick={() => {
            openWrapper === index ? setOpenWrapper(0) : setOpenWrapper(index);
            !openLateral && setOpenLateral(true);
          }}
        >
          <InfoIcon style={{ color: "#213061", fontSize: 20 }} />
        </Box>
        <Box
          height="100%"
          width="calc(100% - 44px)"
          style={{ cursor: "pointer", borderBottom: "solid 1px #f3f3f3", justifyContent: "space-between", alignItems: "center" }}
          onClick={() => {
            openWrapper === index ? setOpenWrapper(0) : setOpenWrapper(index);
            !openLateral && setOpenLateral(true);
          }}
        >
          <Text p={1} fontSize="min(1cqw, 14px)" width="calc(100% - 44px)">
            Informations sur la période
          </Text>
          <Box
            width={44}
            height={35}
            justifyContent="center"
            alignItems="center"
            style={{
              borderRadius: 5,
              transform: openWrapper === index ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform ease 200ms",
            }}
          >
            <KeyboardArrowUpIcon />
          </Box>
        </Box>
      </Box>
      <Box height={openWrapper === index ? "calc(100% - 50px - 50px - 50px)" : 0} style={{ transition: "height ease 100ms" }} overflow="hidden">
        <Box
          width={44}
          height="100%"
          justifyContent="center"
          alignItems="center"
          style={{
            cursor: "pointer",
            backgroundColor: "#d0e1ff45",
          }}
        />
        <Box display="block" height="100%" overflow="scroll" width="calc(100% - 44px)">
          <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
            <Box bgcolor="#fff" width={22} height={22} borderRadius={50} marginRight={1}>
              <Box
                justifyContent="center"
                alignItems="center"
                bgcolor="#21306150"
                border="1px solid #213061"
                width={22}
                height={22}
                borderRadius={50}
              >
                <PersonIcon style={{ color: "#213061", fontSize: 14 }} />
              </Box>
            </Box>

            <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 500 }}>
              {infos?.patient?.firstname} {infos?.patient?.lastname}
            </Text>
          </Box>
          {infos?.patient?.address && (
            <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
              <Box bgcolor="#fff" width={22} height={22} borderRadius={50} marginRight={1}>
                <Box
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="#21306150"
                  border="1px solid #213061"
                  width={22}
                  height={22}
                  borderRadius={50}
                >
                  <LocationOnIcon style={{ color: "#213061", fontSize: 14 }} />
                </Box>
              </Box>

              <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 500 }}>
                {infos?.patient?.address}
              </Text>
            </Box>
          )}
          {infos?.patient?.phoneNumber && (
            <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
              <Box bgcolor="#fff" width={22} height={22} borderRadius={50} marginRight={1}>
                <Box
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="#21306150"
                  border="1px solid #213061"
                  width={22}
                  height={22}
                  borderRadius={50}
                >
                  <LocalPhoneIcon style={{ color: "#213061", fontSize: 14 }} />
                </Box>
              </Box>
              <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 500 }}>
                {infos?.patient?.phoneNumber}
              </Text>
            </Box>
          )}
          {infos?.patient?.billingMode && (
            <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
              <Box bgcolor="#fff" width={22} height={22} borderRadius={50} marginRight={1}>
                <Box
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="#21306150"
                  border="1px solid #213061"
                  width={22}
                  height={22}
                  borderRadius={50}
                >
                  <PixIcon style={{ color: "#213061", fontSize: 14 }} />
                </Box>
              </Box>
              <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 500 }}>
                {getBillingMode(infos?.patient?.billingMode)}
              </Text>
            </Box>
          )}

          {infos?.patient?.relocation?.label && (
            <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
              <Box bgcolor="#fff" width={22} height={22} borderRadius={50} marginRight={1}>
                <Box
                  justifyContent="center"
                  alignItems="center"
                  bgcolor="#21306150"
                  border="1px solid #213061"
                  width={22}
                  height={22}
                  borderRadius={50}
                >
                  <EditRoadIcon style={{ color: "#213061", fontSize: 14 }} />
                </Box>
              </Box>
              <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 500 }}>
                {infos?.patient?.relocation?.label} {infos?.patient?.kmSupp ? `- ${infos?.patient?.kmSupp}kms` : ""}
              </Text>
            </Box>
          )}
          {!!uniqOtherBillingMode?.length && (
            <Box flexDirection="column" mt={2}>
              <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
                <Text color="#f41a1a" fontSize={12} style={{ fontSize: "12px", fontWeight: 500 }}>
                  Autres moyens de facturation
                </Text>
              </Box>
              {uniqOtherBillingMode?.map((billingMode) => (
                <Box justifyContent="flex-start" alignItems="center" p={1} pb={0} key={billingMode}>
                  <Box bgcolor="#fff" width={22} height={22} borderRadius={50} marginRight={1}>
                    <Box
                      justifyContent="center"
                      alignItems="center"
                      bgcolor="#ab000050"
                      border="1px solid #ab0000"
                      width={22}
                      height={22}
                      borderRadius={50}
                    >
                      <PixIcon style={{ color: "#ab0000", fontSize: 14 }} />
                    </Box>
                  </Box>

                  <Text fontSize={12} color="#f41a1a" style={{ fontSize: "12px", fontWeight: 500 }}>
                    {getBillingMode(billingMode)}
                  </Text>
                </Box>
              ))}
            </Box>
          )}
          {!!uniqOtherRelocation?.length && (
            <Box flexDirection="column" mt={2}>
              <Box justifyContent="flex-start" alignItems="center" p={1} pb={0}>
                <Text color="#f41a1a" fontSize={12} style={{ fontSize: "12px", fontWeight: 500 }}>
                  Autres déplacements
                </Text>
              </Box>
              {uniqOtherRelocation?.map(({ relocation, kmSupp }) => (
                <Box justifyContent="flex-start" alignItems="center" p={1} pb={0} key={relocation}>
                  <Box bgcolor="#fff" width={22} height={22} borderRadius={50} marginRight={1}>
                    <Box
                      justifyContent="center"
                      alignItems="center"
                      bgcolor="#ab000050"
                      border="1px solid #ab0000"
                      width={22}
                      height={22}
                      borderRadius={50}
                    >
                      <EditRoadIcon style={{ color: "#ab0000", fontSize: 14 }} />
                    </Box>
                  </Box>
                  <Text fontSize={12} color="#f41a1a" style={{ fontSize: "12px", fontWeight: 500 }}>
                    {relocation} {kmSupp ? `- ${kmSupp}kms` : ""}
                  </Text>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

export function WrapperPraticiens({
  praticiens,
  openWrapper,
  setOpenWrapper,
  index,
  setOpenLateral,
  openLateral,
}: {
  praticiens: (UserWithRoleInfo & { color: string })[];
  openWrapper: number;
  setOpenWrapper: any;
  index: number;
  setOpenLateral: any;
  openLateral: boolean;
}) {
  const { user } = useMongoContext();
  const currentUser = user?.customData;

  return (
    <>
      <Box style={{ justifyContent: "space-between", alignItems: "center" }} width="100%" height={50}>
        <Box
          width={44}
          height="100%"
          justifyContent="center"
          alignItems="center"
          style={{
            cursor: "pointer",
            backgroundColor: "#ffd0ea45",
          }}
          onClick={() => {
            openWrapper === index ? setOpenWrapper(0) : setOpenWrapper(index);
            !openLateral && setOpenLateral(true);
          }}
        >
          <GroupsIcon style={{ color: "#612144", fontSize: 20 }} />
        </Box>
        <Box
          height="100%"
          width="calc(100% - 44px)"
          style={{ cursor: "pointer", borderBottom: "solid 1px #f3f3f3", justifyContent: "space-between", alignItems: "center" }}
          onClick={() => {
            openWrapper === index ? setOpenWrapper(0) : setOpenWrapper(index);
            !openLateral && setOpenLateral(true);
          }}
        >
          <Text p={1} fontSize="min(1cqw, 14px)" width="calc(100% - 44px)">
            Collaborateurs sur la période
          </Text>
          <Box
            width={44}
            height={35}
            justifyContent="center"
            alignItems="center"
            style={{
              borderRadius: 5,
              transform: openWrapper === index ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform ease 200ms",
            }}
          >
            <KeyboardArrowUpIcon />
          </Box>
        </Box>
      </Box>
      <Box height={openWrapper === index ? "calc(100% - 50px - 50px - 50px)" : 0} style={{ transition: "height ease 100ms" }} overflow="hidden">
        <Box
          width={44}
          height="100%"
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundColor: "#ffd0ea45",
          }}
        />
        <Box display="block" height="100%" overflow="scroll" width="calc(100% - 44px)">
          {praticiens?.map((p) => {
            return (
              <Box key={p?.userId} justifyContent="flex-start" alignItems="center" m="4px" p="4px">
                <Box bgcolor="#fff" width={22} height={22} borderRadius={50} marginRight={1}>
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    bgcolor={`${p?.color}50`}
                    border={`1px solid ${p?.color}`}
                    width={22}
                    height={22}
                    borderRadius={50}
                  >
                    <FaUserNurse style={{ color: p?.color, fontSize: 12 }} />
                  </Box>
                </Box>
                <Text fontSize={12} style={{ fontSize: "12px", fontWeight: 500 }}>
                  {p?.userFirstName} {p?.userLastName} {currentUser?._id === p?.userId ? " (moi)" : ""}
                </Text>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
}

export function WrapperCare({
  cares,
  openWrapper,
  setOpenWrapper,
  index,
  setOpenLateral,
  openLateral,
}: {
  cares: Care[];
  openWrapper: number;
  setOpenWrapper: any;
  index: number;
  setOpenLateral: any;
  openLateral: boolean;
}) {
  return (
    <>
      <Box style={{ justifyContent: "space-between", alignItems: "center" }} width="100%" height={50}>
        <Box
          width={44}
          height="100%"
          justifyContent="center"
          alignItems="center"
          style={{
            cursor: "pointer",
            backgroundColor: "#70efc445",
          }}
          onClick={() => {
            openWrapper === index ? setOpenWrapper(0) : setOpenWrapper(index);
            !openLateral && setOpenLateral(true);
          }}
        >
          <ArticleIcon style={{ color: "#134041", fontSize: 20 }} />
        </Box>
        <Box
          height="100%"
          width="calc(100% - 44px)"
          style={{ cursor: "pointer", borderBottom: "solid 1px #f3f3f3", justifyContent: "space-between", alignItems: "center" }}
          onClick={() => {
            openWrapper === index ? setOpenWrapper(0) : setOpenWrapper(index);
            !openLateral && setOpenLateral(true);
          }}
        >
          <Text p={1} fontSize="min(1cqw, 14px)" width="calc(100% - 44px)">
            Traitements sur la période
          </Text>
          <Box
            width={44}
            height={35}
            justifyContent="center"
            alignItems="center"
            style={{
              borderRadius: 5,
              transform: openWrapper === index ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform ease 200ms",
            }}
          >
            <KeyboardArrowUpIcon />
          </Box>
        </Box>
      </Box>
      <Box height={openWrapper === index ? "calc(100% - 50px - 50px - 50px)" : 0} style={{ transition: "height ease 100ms" }} overflow="hidden">
        <Box
          width={44}
          height="100%"
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundColor: "#70efc445",
          }}
        />
        <Box display="block" height="100%" overflow="scroll" width="calc(100% - 44px)">
          {cares?.map((care) => {
            return (
              <Box key={care._id} justifyContent="flex-start" alignItems="center" p={1} pb={0}>
                <Box bgcolor="#fff" width={22} height={22} borderRadius={50} marginRight={1}>
                  <Box
                    justifyContent="center"
                    alignItems="center"
                    bgcolor={`${care.category?.color}50`}
                    border={`1px solid ${care.category?.color}`}
                    width={22}
                    height={22}
                    borderRadius={50}
                  >
                    <Text fontSize={10} color={care.category?.color} fontWeight={500}>
                      {care?.name?.slice(0, 2)}
                    </Text>
                  </Box>
                </Box>

                <p style={{ fontSize: "12px", fontWeight: 500, margin: 0 }}>
                  {care?.name}{" "}
                  {care?.quotations?.map((q, i) => (
                    <span
                      key={`${care._id}-${q.keyLetter.label}-${q.coefficient.value}${i}`}
                      style={{ color: "#ff008d", fontSize: "11px", fontWeight: 500, margin: 0 }}
                    >
                      ({q.keyLetter.label} {q.coefficient.value}){care?.quotations?.length - 1 !== i && <Text> + </Text>}
                    </span>
                  ))}
                </p>
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
}
