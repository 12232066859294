import { Box, Button, Text } from "@/ui";
import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Office, Patient, UserWithRoleInfo } from "@/types";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useOfficeContext } from "@/app/common/provider/OfficeProvider";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { format } from "@/utils/format";
import { useQueryParams } from "@/utils/useQueryParams";
import qs from "query-string";
import { countBy, uniqBy } from "lodash";
import { useAppContext } from "@/app/common/provider/AppProvider";
import Select from "react-select";
import { ModalConfirmDone } from "../../../../../components/nurse/ModalUpdateSeance";
import toast from "react-hot-toast";
import { add, eachDayOfInterval } from "date-fns";
import PersonIcon from "@mui/icons-material/Person";
import { getRequestFilter, getRequestFilterToUpdate } from "../../../../../utils/getRequestFilter";
import { useThemeUI } from "theme-ui";
import { gradient } from "@/theme/ThemeProvider";

const statusOptionsClient = [
  { label: "Tous les statuts", value: null },
  { label: "Séances à faire", value: "to-do" },
  { label: "Séances à envoyer", value: "done" },
  { label: "Séances envoyées", value: "ready-to-bill" },
];

const colors = ["#3288bd", "#d53e4f", "#98a361", "#abdda4", "#fdae61", "#f46d43", "#a598f5", "#66c2a5", "#d53ea0", "#3ed5b0", "#6ed53e", "#fb7f7f"];

export default function Activity() {
  const { dbName } = useApplicationContext();
  const { client, user } = useMongoContext();
  const { currentOfficeInfo } = useOfficeContext();
  const { setLoading, loading } = useAppContext();
  let statusFiltered = statusOptionsClient;
  const params = useQueryParams();
  const { theme } = useThemeUI();
  const [patients, setPatients] = useState<any>([]);
  const [seancesCountbyPatient, setSeanceCountByPatient] = useState<any>({});
  const [praticiens, setPraticiens] = useState<(UserWithRoleInfo & { color: string })[]>([]);

  const [selectedPatient, setSelectedPatient] = useState<Patient>(null);

  const [startVisible, setStartVisible] = useState(false);
  const [endVisible, setEndVisible] = useState(false);

  const [needRefresh, setNeedRefresh] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  async function getDataSeances() {
    try {
      if (!currentOfficeInfo) {
        let to = qs.stringify({ ...params }, { arrayFormat: "index" });
        navigate(`/nurse/offices?${to}`);
      }
      if (params?.start && params?.end) {
        setLoading(true);
        const seancesCollection = client.db(dbName).collection("Seance");
        const requestFilter = getRequestFilter(params);
        let seancesFiltered = await seancesCollection.find(requestFilter, {
          projection: {
            status: 1,
            patient: 1,
            doneBy: 1,
            createdBy: 1,
            _id: 1,
          },
        });
        const seanceCountBy = countBy(seancesFiltered.map((c) => c.patient));
        setSeanceCountByPatient(seanceCountBy);
        const officeCollection = client.db(dbName).collection("Office");
        let office: Office = await officeCollection.findOne({
          _id: currentOfficeInfo.officeId,
        });
        const userCollaborator = office?.members?.find((p) => p?.userId === user?.customData?._id);

        const collaboratorSet = uniqBy(office?.members, "userId")
          ?.filter((p) => p && p?.userId !== user?.customData?._id)
          ?.map((c, i) => ({ ...c, color: colors[i + 1] }))
          ?.sort((a, b) => String(a?.userLastName).toLowerCase().trim().localeCompare(String(b?.userLastName).toLowerCase().trim()));
        collaboratorSet.unshift({ ...userCollaborator, color: colors[0] });

        setPraticiens(collaboratorSet);
        const patientCollection = client.db(dbName).collection("Patient");
        let patients = await patientCollection.find({ deleted: false });

        const filteredPatient = patients
          //@ts-ignore
          ?.filter((p) => seanceCountBy[p._id]);

        if (!filteredPatient?.find((p) => p?._id === selectedPatient?._id)) {
          const patienZero = filteredPatient?.sort((a, b) =>
            String(a?.lastname).toLowerCase().trim().localeCompare(String(b?.lastname).toLowerCase().trim())
          )[0];
          if (patienZero) {
            setSelectedPatient(patienZero);
            let to = qs.stringify({ ...params }, { arrayFormat: "index" });
            navigate(`/nurse/offices/${currentOfficeInfo?.officeId}/agenda/${patienZero?._id}?${to}`, { replace: true });
          }
        }

        setPatients(patients);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  }

  // USE EFFECT TO GET SEANCE COUNT
  useEffect(() => {
    if (params.end && params.start) {
      if (user && client && currentOfficeInfo) {
        getDataSeances();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.start, params.end, params.status, params.collaborator, user, client, currentOfficeInfo]);

  // USE EFFECT TO GET SEANCE COUNT ON NEED REFRESH DATA
  useEffect(() => {
    if (user && client && needRefresh) {
      getDataSeances();
      setNeedRefresh(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client, needRefresh]);

  // USE EFFECT CHECK IF CURRENT SELECTED PATIENT AND URL PATH ARE CORRECT
  useEffect(() => {
    const patientIdUrl = location?.pathname.split("agenda/")[1];

    if (
      ((!loading && user && client && selectedPatient && !seancesCountbyPatient[selectedPatient?._id] && patientIdUrl !== selectedPatient?._id) ||
        (!loading && user && client && !selectedPatient && patientIdUrl)) &&
      currentOfficeInfo?.officeId
    ) {
      setSelectedPatient(null);
      let to = qs.stringify({ ...params }, { arrayFormat: "index" });
      navigate(`/nurse/offices/${currentOfficeInfo?.officeId}/agenda?${to}`, {
        replace: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, client, loading, location, currentOfficeInfo]);

  async function updateAllSeanceToDone(values: any) {
    setLoading(true);
    if (values?.praticien?.value?.userId && currentOfficeInfo?.officeId) {
      try {
        const seancesCollection = client.db(dbName).collection("Seance");
        if (params.start && params.end) {
          const surcharges = [];
          if (values?.mie) surcharges.push("mie");
          if (values?.mci) surcharges.push("mci");
          if (values?.mau) surcharges.push("mau");

          const set = {
            status: "done",
            doneBy: values?.praticien?.value.userId,
            nightRate: values?.nightRate,
            billingMode: values?.billingMode?.value,
            relocation: values?.relocation?.value,
            ...(!!surcharges?.length ? { surcharges } : {}),
            ...(values?.kmSupp ? { kmSupp: String(values?.kmSupp) } : {}),
            ...(values?.replacing?.value.userId ? { replacing: values?.replacing?.value.userId } : {}),
          };
          const unset = {
            ...(!values?.replacing ? { replacing: "" } : {}),
            ...(!values?.kmSupp ? { kmSupp: "" } : {}),
            ...(!surcharges?.length ? { surcharges: "" } : {}),
          };
          await seancesCollection.updateMany(getRequestFilterToUpdate({ params, patient: selectedPatient?._id }), {
            $set: set,
            $unset: unset,
          });
          let to = qs.stringify({ ...params }, { arrayFormat: "index" });
          navigate(`/nurse/offices/${currentOfficeInfo?.officeId}/agenda?${to}`, {
            replace: true,
          });
          toast.success("Séance mise à jour");
          setSelectedPatient(null);
          setNeedRefresh(true);
        }
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        toast.error("Erreur lors de la mise à jour des séances");
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      toast.error("Erreur lors de la mise à jour des séances");
    }
  }

  const collaboratorSet = uniqBy(
    praticiens.map((c) => ({
      label: c.userFirstName + " " + c.userLastName,
      value: { id: c.userId, color: c.color },
    })),
    "value"
  );

  const collaboratorValue = [{ label: "Tout le cabinet", value: null }, ...collaboratorSet];

  const formatOptionLabel = ({ value, label, customAbbreviation }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {value?.color && (
        <PersonIcon
          style={{
            color: value?.color,
            fontSize: 18,
            margin: 2,
            marginRight: 5,
          }}
        />
      )}
      <div>{label}</div>
    </div>
  );

  return (
    <Box
      flexDirection="column"
      width="98%"
      height="98%"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: "#fffffff6", borderRadius: 5 }}
    >
      <Box justifyContent="center" alignItems="center" width="100%" height="60px" position="relative">
        <Box
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          position="relative"
          borderBottom={`solid 1px ${theme.colors.light}`}
        >
          <Box justifyContent="center" alignItems="center">
            <Text fontWeight={300} fontSize={26} pl={3}>
              Agenda
            </Text>
          </Box>

          <Box justifyContent="center" alignItems="flex-end" pb={1}>
            {/* ACTION BUTTON */}
            {currentOfficeInfo?.role === "owner" && selectedPatient && params.status === "to-do" && (
              <Box mr={1}>
                <ModalConfirmDone
                  patient={selectedPatient}
                  praticiens={praticiens}
                  onConfirm={updateAllSeanceToDone}
                  buttonText="Valider la période pour ce patient"
                  updateType="updateManyDone"
                />
              </Box>
            )}
            {/* FILTER STATUS */}
            <Box style={{ position: "relative", width: "180px" }} flexDirection="column" pr={1}>
              <Text fontSize={12} pr={1}>
                Statut :
              </Text>
              <Select
                options={statusFiltered}
                onMenuOpen={() => {
                  setEndVisible(false);
                  setStartVisible(false);
                }}
                styles={{
                  container: (style) => ({ ...style, width: "100%" }),
                  control: (style) => ({
                    ...style,
                    borderColor: "#c7c7c7",
                    height: "28px",
                    maxHeight: "28px",
                    minHeight: "28px",
                    borderRadius: "5px",
                    fontSize: 13,
                    fontWeight: 600,
                  }),
                  indicatorsContainer: (style) => ({ ...style, padding: 0 }),
                  valueContainer: (style) => ({ ...style, padding: 0 }),
                  option: (style, { isSelected }) => ({
                    ...style,
                    background: isSelected ? "#f72585" : "transparent",
                    padding: "3px 4px",
                    color: isSelected ? "#fff" : "",
                  }),
                  menuList: (style) => ({ ...style, maxHeight: 200 }),
                  singleValue: () => ({ paddingLeft: 5, color: "#454545" }),
                }}
                placeholder="Sélectionner un cabinet"
                defaultValue={statusFiltered?.find((c) => c.value === params.status) || statusFiltered[0]}
                value={statusFiltered?.find((c) => c.value === params.status) || statusFiltered[0]}
                onChange={(item) => {
                  let to = qs.stringify({ ...params, status: item.value }, { arrayFormat: "index" });
                  navigate(`${location.pathname}?${to}`, { replace: true });
                }}
              />
            </Box>
            {/* FILTER COLLABORATOR */}
            <Box style={{ position: "relative", width: "250px" }} flexDirection="column" pr={2}>
              <Text fontSize={12} pr={1}>
                Collaborateur :
              </Text>
              <Select
                formatOptionLabel={formatOptionLabel}
                options={collaboratorValue}
                onMenuOpen={() => {
                  setEndVisible(false);
                  setStartVisible(false);
                }}
                styles={{
                  container: (style) => ({ ...style, width: "100%" }),
                  control: (style) => ({
                    ...style,
                    borderColor: "#c7c7c7",
                    height: "28px",
                    maxHeight: "28px",
                    minHeight: "28px",
                    borderRadius: "5px",
                    fontSize: 13,
                    fontWeight: 600,
                  }),
                  indicatorsContainer: (style) => ({ ...style, padding: 0 }),
                  valueContainer: (style) => ({ ...style, padding: 0, flexWrap: "nowrap" }),
                  option: (style, { isSelected }) => ({
                    ...style,
                    background: isSelected ? "#f72585" : "transparent",
                    padding: "3px 4px",
                    color: isSelected ? "#fff" : "",
                  }),
                  menuList: (style) => ({ ...style, maxHeight: 314 }),
                  singleValue: () => ({ paddingLeft: 5, color: "#454545" }),
                }}
                placeholder="Sélectionner un collaborateur"
                // to change with
                defaultValue={collaboratorValue.find((c) => c.value?.id === params.collaborator) || collaboratorValue[0]}
                value={collaboratorValue.find((c) => c.value?.id === params.collaborator) || collaboratorValue[0]}
                onChange={(item) => {
                  let to = qs.stringify({ ...params, collaborator: item.value?.id }, { arrayFormat: "index" });
                  navigate(`${location.pathname}?${to}`, { replace: true });
                }}
              />
            </Box>
            {/* FILTER PERIODE START */}
            <Box style={{ position: "relative" }} flexDirection="column" pr={1}>
              <Text fontSize={12}>Période du :</Text>
              <Box
                p="2px"
                pl={1}
                pr={1}
                style={{
                  borderRadius: 5,
                  backgroundColor: "#fff",
                  border: "solid 1px",
                  borderColor: "#c7c7c7",
                  cursor: "pointer",
                  fontSize: 13,
                  fontWeight: 600,
                  maxHeight: "28px",
                  minHeight: "28px",
                  color: "#454545",
                }}
                alignItems="center"
                onClick={() => {
                  setEndVisible(false);
                  setStartVisible(!startVisible);
                }}
              >
                {format(new Date(Number(params?.start)), "dd/MM/yyyy")}
              </Box>

              {startVisible && (
                <Box
                  style={{
                    position: "absolute",
                    top: "100%",
                    right: 0,
                    marginTop: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "#fff",
                    boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
                    borderRadius: "5px",
                    overflow: "hidden",
                    zIndex: 99999,
                  }}
                >
                  <Calendar
                    onChange={(c) => {
                      const start = new Date(c);
                      const end = new Date(Number(params.end));
                      start.setHours(0, 0);
                      if (start.getTime() > end.getTime() || eachDayOfInterval({ start, end: end }).length > 31) {
                        const endToSet = add(start, { days: 31 });
                        let to = qs.stringify(
                          {
                            ...params,
                            start: start.getTime(),
                            end: endToSet.getTime(),
                          },
                          { arrayFormat: "index" }
                        );
                        console.log("=> ~ file: index.tsx:625 ~ Activity ~ to:", to);
                        navigate(`${location.pathname}?${to}`, {
                          replace: true,
                        });
                      } else {
                        let to = qs.stringify({ ...params, start: start.getTime() }, { arrayFormat: "index" });
                        navigate(`${location.pathname}?${to}`, {
                          replace: true,
                        });
                      }
                    }}
                    value={new Date(Number(params.start))}
                  />
                  <Box p={1}>
                    <Button onClick={() => setStartVisible(false)}>Fermer</Button>
                  </Box>
                </Box>
              )}
            </Box>
            {/* FILTER PERIODE END*/}
            <Box style={{ position: "relative" }} flexDirection="column" pr={"45px"}>
              <Text fontSize={12}>Au* :</Text>
              <Box
                p="2px"
                pl={1}
                pr={1}
                style={{
                  borderRadius: 5,
                  backgroundColor: "#fff",
                  border: "solid 1px",
                  borderColor: "#c7c7c7",
                  cursor: "pointer",
                  fontSize: 13,
                  maxHeight: "28px",
                  minHeight: "28px",
                  fontWeight: 600,
                  color: "#454545",
                }}
                alignItems="center"
                onClick={() => {
                  setStartVisible(false);
                  setEndVisible(!endVisible);
                }}
              >
                {format(new Date(Number(params.end)), "dd/MM/yyyy")}
              </Box>

              {endVisible && (
                <Box
                  style={{
                    position: "absolute",
                    top: "100%",
                    right: 0,
                    marginTop: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    backgroundColor: "#fff",
                    boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
                    borderRadius: "5px",
                    overflow: "hidden",
                    zIndex: 99999,
                  }}
                >
                  <Calendar
                    minDate={new Date(Number(params.start))}
                    maxDate={add(new Date(Number(params.start)), { days: 31 })}
                    onChange={(c) => {
                      const date = new Date(c);
                      date.setHours(23, 59);
                      let to = qs.stringify({ ...params, end: date.getTime() }, { arrayFormat: "index" });
                      navigate(`${location.pathname}?${to}`);
                    }}
                    value={new Date(Number(params.end))}
                  />
                  <Box p={1}>
                    <Button onClick={() => setEndVisible(false)}>Fermer</Button>
                  </Box>
                </Box>
              )}
            </Box>

            <Box style={{ position: "absolute", bottom: 1, right: 85 }}>
              <Text color="#454545" fontSize={9} fontWeight="500">
                * 31 jours d'interval maximum
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          width: "100%",
          height: "calc(100% - 60px)",
          position: "relative",
        }}
        justifyContent="center"
        alignItems="center"
      >
        {!Object.keys(seancesCountbyPatient).length && !loading && (
          <Box style={{ position: "absolute" }} width="50%" justifyContent="center" alignItems="center">
            <Box
              pb={1}
              fontSize={20}
              fontWeight="300"
              flexDirection="row"
              flexWrap="wrap"
              textAlign="center"
              justifyContent="center"
              alignItems="center"
            >
              Aucun patient
              {params?.collaborator && (
                <span>
                  &nbsp;de&nbsp;
                  <span style={{ fontWeight: 600, color: theme.colors.primary as any }}>
                    {collaboratorValue.find((c) => c?.value?.id === params?.collaborator)?.label}
                  </span>
                </span>
              )}
              {params?.status && (
                <span>
                  &nbsp;pour les&nbsp;
                  <span style={{ fontWeight: 600, color: theme.colors.primary as any }}>
                    {statusFiltered.find((c) => c?.value === params?.status)?.label}
                  </span>
                </span>
              )}
              {params?.start && (
                <>
                  &nbsp;sur la période du&nbsp;
                  <span style={{ fontWeight: 600, color: theme.colors.primary as any }}>
                    {format(new Date(Number(params.start)), "EEEE dd MMMM yyyy")}
                  </span>
                </>
              )}
              {params?.end && (
                <>
                  &nbsp;au&nbsp;
                  <span style={{ fontWeight: 600, color: theme.colors.primary as any }}>
                    {format(new Date(Number(params.end)), "EEEE dd MMMM yyyy")}
                  </span>
                </>
              )}
            </Box>
          </Box>
        )}
        {Object.keys(seancesCountbyPatient).length > 0 && (
          <>
            <Box flexDirection="column" width="20%" pr={2} pl={2} style={{ overflowY: "scroll" }} height="100%">
              {patients
                //@ts-ignore
                ?.filter((p) => seancesCountbyPatient[p._id])
                ?.sort((a, b) => String(a?.lastname).toLowerCase().trim().localeCompare(String(b?.lastname).toLowerCase().trim()))
                ?.map((patient, index) => {
                  if (location.pathname.includes(`${patient?._id}`) && !location.pathname.includes(`${selectedPatient?._id}`)) {
                    setSelectedPatient(patient);
                    let to = qs.stringify({ ...params }, { arrayFormat: "index" });
                    navigate(`${patient?._id}?${to}`, { replace: true });
                  }
                  return (
                    <Box
                      onClick={() => {
                        setSelectedPatient(patient);
                        let to = qs.stringify({ ...params }, { arrayFormat: "index" });
                        navigate(`${patient?._id}?${to}`, { replace: true });
                      }}
                      key={patient._id}
                      alignItems="center"
                      justifyContent="space-between"
                      bgcolor={patient?._id === selectedPatient?._id ? theme.colors.light : "#f5f5f5"}
                      style={{
                        margin: 5,
                        width: "90%",
                        borderRadius: 25,
                        paddingLeft: 15,
                        minHeight: 35,
                        cursor: "pointer",
                        position: "relative",
                      }}
                    >
                      <Box bgcolor="#fff" width={25} height={25} borderRadius={50} mr={1}>
                        <Box
                          width={25}
                          height={25}
                          borderRadius={50}
                          bgcolor={`${theme.colors.gradient[String(index % 8) as keyof gradient]}30`}
                          border={`1px solid ${theme.colors.gradient[String(index % 8) as keyof gradient]}`}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <PersonIcon style={{ color: theme.colors.gradient[String(index % 8) as keyof gradient], fontSize: 18 }} />
                        </Box>
                      </Box>
                      <Text display="block" fontWeight="400" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap" width="100%">
                        {patient.lastname} {patient.firstname}
                      </Text>
                      <Box
                        style={{
                          position: "absolute",
                          borderRadius: 15,
                          fontSize: 11,
                          bottom: -5,
                          right: 0,
                          backgroundColor: "#f4fdeb",
                          padding: "0px 5px",
                        }}
                      >
                        {seancesCountbyPatient[patient._id]} séance
                        {seancesCountbyPatient[patient._id] > 1 ? "s" : ""}
                      </Box>
                    </Box>
                  );
                })}
            </Box>
            <Box width="80%" pl={2} height="100%">
              {selectedPatient && (
                <OutletWithSeances patient={selectedPatient} praticiens={praticiens} setNeedRefresh={setNeedRefresh} needRefresh={needRefresh} />
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}

function OutletWithSeances({
  patient,
  praticiens,
  setNeedRefresh,
  needRefresh,
}: {
  patient: Patient;
  praticiens: (UserWithRoleInfo & { color: string })[];
  setNeedRefresh: any;
  needRefresh: boolean;
}) {
  return <Outlet context={{ patient, praticiens, setNeedRefresh, needRefresh }} />;
}
