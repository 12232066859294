import * as Realm from "realm-web";
import { fetchRefreshAccessTokenNurse } from "@/api/nurse/fetchRefreshAccessToken";
import { fetchRefreshAccessTokenTaxi } from "@/api/taxi/fetchRefreshAccessToken";
import { getRefreshToken, setLocalStorageAccessToken } from "@/utils/localStorage";

export async function loginCustomJwt(jwt: string, currentApp: Realm.App, application?: string): Promise<Realm.User | undefined> {
  try {
    // Create a Custom JWT credential
    console.log("=========> jwt", jwt);
    const credentials = Realm.Credentials.jwt(jwt);
    // Authenticate the user
    console.log("=========> login realm");
    const user: Realm.User = await currentApp.logIn(credentials);
    if (user?.id !== currentApp.currentUser?.id) {
      throw new Error("user.id different from app.currentUser.id");
    }
    return user;
  } catch (err: any) {
    console.log("need refresh token");

    if (!err.message?.includes("token is expired")) {
      console.error("authenticating to Realm", err);
    } else {
      const refreshToken = await getRefreshToken();
      let result = null;

      if (application === "mobility") {
        result = await fetchRefreshAccessTokenNurse({ refreshToken, application });
      }
      if (application === "taxi") {
        result = await fetchRefreshAccessTokenTaxi({ refreshToken });
      }
      if (application === "inovidel") {
        result = await fetchRefreshAccessTokenNurse({ refreshToken, application });
      }
      console.log("======> refreshAccessToken result", result);
      await setLocalStorageAccessToken(result.body.accessToken);
      console.log(result.body.idToken);
      if (!result.body.idToken) {
        currentApp.currentUser?.logOut();
        await setLocalStorageAccessToken("");
      }
      // Create a Custom JWT credential
      const credentials = Realm?.Credentials?.jwt(result.body.idToken);
      // Authenticate the user
      const user: Realm.User = await currentApp.logIn(credentials);
      return user;
    }
  }
}

export const reconizedProfessionsByFiness = ["6", "7", "5"];
