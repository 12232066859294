import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useAppContext } from "@/app/common/provider/AppProvider";
import { getIdToken } from "@/utils/localStorage";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";

export function RootTaxi() {
  const { application } = useApplicationContext();
  const { app } = useMongoContext();
  const { setLoading } = useAppContext();

  async function start() {
    try {
      let token = getIdToken();
      console.log("=>  token:", token);
      console.log("=>  application:", application);
      console.log("=>  currentUser", app);

      // if (app?.currentUser?.isLoggedIn) {
      //   setUser(app?.currentUser);
      //   setApp(app);
      //   setClient(app.currentUser.mongoClient("mongodb-atlas"));
      //   setLoading(false);
      //   if (location?.pathname === "/" || location?.pathname === `/${application}`) {
      //     navigate(`/${application}/home`, { replace: true });
      //   }
      // } else if (token && !app?.currentUser?.isLoggedIn) {
      //   //@ts-ignore
      //   loginCustomJwt(token as string, app, application)
      //     .then((user) => {
      //       setUser(user);
      //       setApp(app);
      //       setClient(app.currentUser.mongoClient("mongodb-atlas"));
      //     })
      //     .catch(() => {
      //       app?.currentUser?.logOut();
      //       setLocalStorageAccessToken("");
      //       setIdToken("");
      //       navigate("/welcome");
      //     })
      //     .finally(() => {
      //       setLoading(false);
      //     });
      // } else {
      //   navigate("/welcome");
      // }
      if (application === null) {
        // setApp(null);
        // setClient(null);
        // setUser(null);
      }
    } catch (e) {
      console.log("=> error:", e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
}
