import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useAppContext } from "@/app/common/provider/AppProvider";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { Header, LeftNavBar } from "@/app/taxi/AppShell";
import { InvoiceTaxi } from "@/types";
import { Box, Button, Text, Card } from "@/ui";
import { useQueryParams } from "@/utils/useQueryParams";
import { getBlob, getDownloadURL } from "@/api/taxi/fetchDownloadURL";
import { format } from "date-fns";
import qs from "query-string";
import JSZip from "jszip";
//@ts-ignore
import { saveAs } from "file-saver";

// Icons
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Material UI
import { Typography } from "@mui/material";
import { useThemeUI } from "theme-ui";

export default function BillingPage() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useAppContext();
  const { client, user } = useMongoContext();
  const { dbName } = useApplicationContext();
  const [invoices, setInvoices] = useState<InvoiceTaxi[]>([]);
  const { theme } = useThemeUI();

  // Get invoices function
  async function getFilteredInvoices() {
    const invoicesCollection = client.db(dbName).collection("Invoice");

    const invoices = await invoicesCollection.find({
      username: user?.identities[0]?.id,
    });

    return invoices.sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  // Get data function
  async function getData() {
    try {
      const dateFilter = new Date(Number(params.period));
      setLoading(true);

      const invoices = await getFilteredInvoices();
      // Filter invoices for the selected year
      const filteredAndSortedInvoices = invoices.filter((invoice) => invoice.date.getFullYear() === dateFilter.getFullYear());
      setInvoices(filteredAndSortedInvoices);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user && client) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.period, user, client]);

  // Back to period function for navigation bar component
  const handleBackToPeriod = ({ period, order = "negative" }: { period: string; order: string }) => {
    let date = new Date(Number(period));
    date.setFullYear(date.getFullYear() + (order === "negative" ? -1 : +1));
    let to = qs.stringify({ ...params, period: date.getTime() }, { arrayFormat: "index" });
    navigate(`${location.pathname}?${to}`, { replace: true });
  };

  // Navigation Bar Component
  const NavigationBar = ({ period }) => {
    return (
      <Card display="flex" justifyContent="space-between" alignItems="center" style={{ marginBottom: "16px" }}>
        <Button onClick={() => handleBackToPeriod({ period, order: "negative" })}>
          <ArrowBackIcon />
        </Button>
        <Typography variant="h6">Année {format(new Date(Number(period)), "yyyy")}</Typography>
        <Button
          disabled={(new Date(Number(period) + 1000 * 60 * 60 * 24 * 365) > new Date())}
          onClick={() => handleBackToPeriod({ period, order: "positive" })}
        >
          <ArrowForwardIcon />
        </Button>
      </Card>
    );
  };

  // First letter in uppercase function
  const firstLetterUppercase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Add invoice to zip function
  const addInvoiceToZip = async (invoice: InvoiceTaxi, zip?: JSZip) => {
    try {
      console.log("invoice :", invoice);
      const urlInvoice = await getDownloadURL({
        path: `${invoice.documentPath}`,
      });
      const pdfBlob = await getBlob(urlInvoice);
      const urlSplit = invoice.documentPath.split("/");
      const fileName = urlSplit[urlSplit.length - 1];
      zip?.file(fileName, pdfBlob, { binary: true });
    } catch (error) {
      console.log("error :", error);
    }
  };

  // Download invoices by month function
  const downloadInvoicesByMonth = async (invoice: InvoiceTaxi) => {
    try {
      const urlInvoice = await getDownloadURL({
        path: `${invoice.documentPath}`,
      });
      const pdfBlob = await getBlob(urlInvoice);
      if (pdfBlob === undefined) {
        console.error("Error downloading invoice: pdfBlob is undefined");
        return;
      }
      const urlSplit = invoice.documentPath.split("/");
      const fileName = urlSplit[urlSplit.length - 1];
      saveAs(pdfBlob, fileName);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };

  // Download invoices by year function
  const downloadInvoicesByYear = async () => {
    const zip = new JSZip();
    const downloadPromises = invoices.map((invoice) => addInvoiceToZip(invoice, zip));

    // Wait for all download promises to complete
    await Promise.all(downloadPromises);
    zip.generateAsync({ type: "blob" }).then(function (content) {
      // Download the zip file
      saveAs(content, `Factures ${format(new Date(Number(params.period)), "yyyy")}.zip`);
    });
  };

  // Invoice presentation button component
  const renderDownloadButton = (invoice: InvoiceTaxi) => (
    <Button onClick={() => downloadInvoicesByMonth(invoice)}>
      <DownloadIcon fontSize="small" style={{ color: theme.colors.primary as any }} />
      <Text m={0} style={{ marginLeft: "4px" }}>
        Télécharger le PDF
      </Text>
    </Button>
  );

  // Invoice presentation invoice item by month component
  const renderInvoiceByMonth = () =>
    invoices.map((invoice, index) => (
      <Box
        key={index}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        m={2}
        width="100%"
        borderBottom={`solid 1px ${theme.colors.light}`}
      >
        <Text fontSize={20}>
          {`${firstLetterUppercase(format(invoice.date, "MMMM"))} ${format(new Date(Number(params.period)), "yyyy").toUpperCase()}`}
        </Text>
        {renderDownloadButton(invoice)}
      </Box>
    ));

  return (
    <Box flexDirection="column" height="100vh" justifyContent="center" alignItems="center" width="100vw">
      <div className="background-mountain-cofidoc" />
      <div className="background-cloud height40" />
      <div className="background-over" />
      <Header />
      <Box flexDirection="row" width="100%" height="calc(100% - 6vh)" justifyContent="center" alignItems="center">
        <LeftNavBar />
        <Box flexDirection="column" width="calc(100% - 10vw)" height="100%" justifyContent="center" alignItems="center" className="overBg">
          <Box
            flexDirection="column"
            width="98%"
            height="98%"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: "#fffffff6", borderRadius: 5 }}
          >
            {/* Filter */}
            <FilterPeriodBox />

            <Box
              style={{
                height: "calc(100% - 60px)",
              }}
              width="100%"
              justifyContent="center"
              alignItems="center"
              pt={4}
              pl={2}
              pr={2}
            >
              {/* Contenu de "Mes Factures" */}
              <Box
                width="100%"
                style={{
                  overflowY: "scroll",
                }}
                pl={2}
                height="100%"
                flexDirection="column"
              >
                {/* Titre */}
                <Text fontWeight={300} fontSize={25} p={2} textAlign="left">
                  Mes factures
                </Text>

                {/* Barre de navigation */}
                <NavigationBar period={params.period} />

                {/* Contenu de "Mes Factures" */}
                <>
                  {/* Si l'année est supérieure à l'année actuelle ou si il n'y a pas de factures, on affiche un message */}
                  {new Date(Number(params.period)) > new Date() || invoices.length === 0 ? (
                    <Card flexDirection="column" p={2}>
                      <Box justifyContent="center" alignItems="center" width="100%" height="100%">
                        <Text fontSize={18} fontWeight={500}>
                          Aucune facture pour cette période
                        </Text>
                      </Box>
                    </Card>
                  ) : (
                    <>
                      <Card flexDirection="column" p={2} style={{ marginBottom: "16px" }}>
                        {/* Présentation par année */}
                        <Box flexDirection="row" justifyContent="space-between" alignItems="center" p={2} m={2} width="100%">
                          <Text fontSize={20}>{`ANNÉE ${format(new Date(Number(params.period)), "yyyy").toUpperCase()}`}</Text>
                          <Button onClick={() => downloadInvoicesByYear()}>
                            <DownloadIcon fontSize="small" style={{ color: theme.colors.primary as any }} />
                            <Text m={0} style={{ marginLeft: "4px" }}>
                              Télécharger le PDF
                            </Text>
                          </Button>
                        </Box>
                      </Card>
                      <Card flexDirection="column" p={2}>
                        {/* Présentation par mois */}
                        {renderInvoiceByMonth()}
                      </Card>
                    </>
                  )}
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const FilterPeriodBox = () => {
  const params = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [periodVisible, setPeriodVisible] = useState(false);
  const { theme } = useThemeUI();
  return (
    <Box justifyContent="center" alignItems="center" width="100%" height="60px" position="relative">
      <Box
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="100%"
        position="relative"
        borderBottom={`solid 1px ${theme.colors.light}`}
      >
        <Box justifyContent="center" alignItems="center">
          <Text fontWeight={300} fontSize={26} pl={3}>
            Facturation
          </Text>
        </Box>
        <Box justifyContent="center" alignItems="flex-end" pb={1} pr={3}>
          {/* FILTER PERIODE */}
          <Box style={{ position: "relative" }} flexDirection="column">
            <Text fontSize={12}>Période du :</Text>
            <Box
              p="2px"
              pl={1}
              pr={1}
              style={{
                borderRadius: 5,
                backgroundColor: "#fff",
                border: "solid 1px",
                borderColor: "#c7c7c7",
                cursor: "pointer",
                fontSize: 13,
                fontWeight: 600,
                maxHeight: "28px",
                minHeight: "28px",
                color: "#454545",
                width: 100,
              }}
              alignItems="center"
              onClick={() => {
                setPeriodVisible(!periodVisible);
              }}
            >
              {format(new Date(Number(params.period)), "MM/yyyy")}
            </Box>

            {periodVisible && (
              <Box
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
                  borderRadius: "5px",
                  overflow: "hidden",
                  zIndex: 99999,
                }}
              >
                <Calendar
                  maxDetail="year"
                  onChange={(c) => {
                    const period = new Date(c);
                    let to = qs.stringify({ ...params, period: period.getTime() }, { arrayFormat: "index" });
                    navigate(`${location.pathname}?${to}`, {
                      replace: true,
                    });
                    setPeriodVisible(false);
                  }}
                  maxDate={new Date()}
                  value={new Date(Number(params.period))}
                />
                <Box p={1}>
                  <Button onClick={() => setPeriodVisible(false)}>Fermer</Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
