import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useAppContext } from "@/app/common/provider/AppProvider";
import { useOfficeContext } from "@/app/common/provider/OfficeProvider";
import { OfficeWithRole } from "@/types";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";

export function RootNurse() {
  const { setUser, setClient, app, setApp } = useMongoContext();
  const { setLoading } = useAppContext();
  const { setUserOffices } = useOfficeContext();
  const { dbName, application } = useApplicationContext();
  const location = useLocation();
  const navigate = useNavigate();
  const authorizedRoot = ["/nurse/login", "/nurse/forgot-password"];

  async function start() {
    try {
      console.log("=>  currentUser", app);
      if (app?.currentUser?.isLoggedIn) {
        setUser(app?.currentUser);
        setApp(app);
        setClient(app.currentUser.mongoClient("mongodb-atlas"));
        setLoading(false);
        const currentUserId = app?.currentUser?.customData?._id;
        if (currentUserId) {
          const offices = await app.currentUser
            .mongoClient("mongodb-atlas")
            .db(dbName)
            .collection("Office")
            .find({ "members.userId": currentUserId });
          const officesWithRole: OfficeWithRole[] = offices
            .filter((office) => office?.members?.find((member) => member?.role === "owner"))
            .map((office) => {
              const userOffice = office?.members?.find((member) => member?.userId === currentUserId);
              return {
                expireAt: userOffice?.expireAt ? userOffice?.expireAt : null,
                role: userOffice?.role ? userOffice?.role : null,
                officeId: office._id,
                officeName: office.name,
                connexionSillab: office?.connexionSillab ? office?.connexionSillab : null,
                connexionConex: office?.connexionConex ? office?.connexionConex : null,
              };
            });
          setUserOffices(officesWithRole);
        }

        if (location?.pathname === "/" || location?.pathname === `/${application}`) {
          navigate(`/${application}/home`, { replace: true });
        }
      } else if (authorizedRoot.includes(location.pathname)) {
        return;
      } else {
        setApp(null);
        setClient(null);
        setUser(null);
        return navigate("/welcome");
      }
    } catch (e) {
      console.log("=> error:", e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
}
