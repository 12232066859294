import { ThemeProvider } from "styled-components";
import { Theme, ThemeProvider as ThemeUIProvider } from "theme-ui";

export const COFIDOC_COLOR = "#fad3e8";

export type gradient = {
  "1": string;
  "2": string;
  "3": string;
  "4": string;
  "5": string;
  "6": string;
  "7": string;
  "8": string;
};

export const themeCofidoc: Theme = {
  colors: {
    seanceToDo: "#e3e3e3",
    seanceDone: "#ffe196",
    seanceReadyToBill: "#96adff",
    seancePutOnHold: "#ff96c2",
    seanceBilled: "#99ff96",

    primary: "#FF008D",
    light: "#fad3e8",
    dark: "#573246",
    secondary: "#b5cdec",
    lightgray: "#f6f6ff",
    background: "#fff",
    bgPale: "#fef4f9",
    seanceStatus: {
      "to-do": "#e3e3e3",
      done: "#ffe196",
      "ready-to-bill": "#96adff",
      "put-on-hold": "#ff96c2",
      billed: "#99ff96",
    },
    pink: "#FF008D",
    pinkDark: "#c3006b",
    pinkPale: "#fad3e8",
    red: "#e15757",
    gradient: {
      "0": "#fff671",
      "1": "#ffcfd2",
      "2": "#f1c0e8",
      "3": "#cfbaf0",
      "4": "#a3c4f3",
      "5": "#90dbf4",
      "6": "#98f5e1",
      "7": "#b9fbc0",
      "8": "#fbf8cc",
    },
  },
  fonts: {
    body: "system-ui, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
};

export const themeInovie: Theme = {
  colors: {
    seanceToDo: "#e3e3e3",
    seanceDone: "#ffe196",
    seanceReadyToBill: "#96adff",
    seancePutOnHold: "#ff96c2",
    seanceBilled: "#99ff96",

    primary: "#576dc4",
    dark: "#1a2d79",
    light: "#b5cdec",
    secondary: "#fad3e8",
    lightgray: "#f6f6ff",
    background: "#fff",
    bgPale: "#e5ecf4",
    seanceStatus: {
      "to-do": "#e3e3e3",
      done: "#ffe196",
      "ready-to-bill": "#96adff",
      "put-on-hold": "#ff96c2",
      billed: "#99ff96",
    },
    pink: "#FF008D",
    pinkDark: "#c3006b",
    pinkPale: "#fad3e8",
    red: "#e15757",
    gradient: {
      "0": "#fff671",
      "1": "#ffcfd2",
      "2": "#f1c0e8",
      "3": "#cfbaf0",
      "4": "#a3c4f3",
      "5": "#90dbf4",
      "6": "#98f5e1",
      "7": "#b9fbc0",
      "8": "#fbf8cc",
    },
  },
  fonts: {
    body: "system-ui, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
};

export function AppThemeProvider({ children }) {
  const isInovie = process.env.REACT_APP_INOVIE_DEL;
  const theme = isInovie ? themeInovie : themeCofidoc;

  return (
    <ThemeProvider theme={theme}>
      <ThemeUIProvider theme={theme}>{children}</ThemeUIProvider>
    </ThemeProvider>
  );
}
