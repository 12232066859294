/* eslint-disable react-hooks/exhaustive-deps */

import { useNavigate, useLocation } from "react-router-dom";
import { Box, Button, Text, NavLink } from "../../ui";
import { useMongoContext } from "../common/provider/MongoProvider";
import HomeIcon from "@mui/icons-material/Home";
import AssessmentIcon from "@mui/icons-material/Assessment";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useOfficeContext } from "../common/provider/OfficeProvider";
import { fetchSetCurrentOffice } from "@/api/nurse/fetchSetCurrentOffice";
import qs from "query-string";
import { useThemeUI } from "theme-ui";
import { useApplicationContext } from "../common/provider/ApplicationContext";

export function Header() {
  const isInovie = process.env.REACT_APP_INOVIE_DEL;
  return (
    <Box display="flex" width="100%" justifyContent="space-between" height="6vh" zIndex={9} className="overBg">
      <Box>
        <Box display="flex" alignItems="center" justifyContent="center" width="10vw" mr={2} pt={1}>
          {isInovie ? <div className="logo-inovie" style={{ height: "100%" }} /> : <div className="logo-cofidoc" style={{ height: "100%" }} />}
        </Box>
      </Box>
      <Box p={2}>
        <Profile />
      </Box>
    </Box>
  );
}

export function LeftNavBar() {
  const { currentOfficeInfo } = useOfficeContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { theme } = useThemeUI();

  const navigateAgenda = () => {
    if (currentOfficeInfo) {
      const start = new Date(new Date().setDate(new Date().getDate() - 31));
      start.setHours(0, 0);
      const end = new Date(new Date().setDate(new Date().getDate()));
      end.setHours(23, 59);

      let to = qs.stringify(
        {
          start: start.getTime(),
          end: end.getTime(),
        },
        { arrayFormat: "index" }
      );
      navigate(`/nurse/offices/${currentOfficeInfo?.officeId}/agenda?${to}`);
    } else {
      let to = qs.stringify(
        {
          redirect: "agenda",
        },
        { arrayFormat: "index" }
      );
      navigate(`/nurse/offices?${to}`);
    }
  };

  const navigateActivity = () => {
    if (currentOfficeInfo) {
      const start = new Date(new Date().setDate(new Date().getDate() - 31));
      start.setHours(0, 0);
      const end = new Date(new Date().setDate(new Date().getDate()));
      end.setHours(23, 59);

      let to = qs.stringify(
        {
          start: start.getTime(),
          end: end.getTime(),
        },
        { arrayFormat: "index" }
      );
      navigate(`/nurse/offices/${currentOfficeInfo?.officeId}/activity?${to}`);
    } else {
      let to = qs.stringify(
        {
          redirect: "activity",
        },
        { arrayFormat: "index" }
      );
      navigate(`/nurse/offices?${to}`);
    }
  };

  const navigateBilling = () => {
    const period = new Date(new Date().setDate(new Date().getDate()));
    period.setHours(0, 0);

    let to = qs.stringify(
      {
        period: period.getTime(),
      },
      { arrayFormat: "index" }
    );
    navigate(`/nurse/billing?${to}`);
  };

  return (
    <Box display="flex" flexDirection="column" width="10vw" height="100%" zIndex={10} className="overBg" pt="2vh" alignItems="center">
      <NavLink onClick={() => navigate("/nurse/home")} selected={location.pathname.includes("home")}>
        <HomeIcon fontSize="small" style={{ color: theme.colors.dark as any }} />
        <Box pr={1} />
        <Text m={0}>Accueil</Text>
      </NavLink>
      <NavLink onClick={navigateAgenda} selected={location.pathname.includes("offices") && location.pathname.includes("agenda")}>
        <EventAvailableIcon fontSize="small" style={{ color: theme.colors.dark as any }} />
        <Box pr={1} />
        <Text m={0}>Agenda</Text>
      </NavLink>
      <NavLink onClick={navigateActivity} selected={location.pathname.includes("offices") && location.pathname.includes("activity")}>
        <AssessmentIcon fontSize="small" style={{ color: theme.colors.dark as any }} />
        <Box pr={1} />
        <Text m={0}>Activité</Text>
      </NavLink>
      <NavLink onClick={navigateBilling} selected={location.pathname.includes("billing")}>
        <AccountBalanceIcon fontSize="small" style={{ color: theme.colors.dark as any }} />
        <Box pr={1} />
        <Text m={0}>Facturation</Text>
      </NavLink>
    </Box>
  );
}

function Profile() {
  const { app }: any = useMongoContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { application } = useApplicationContext();
  const isInovie = process.env.REACT_APP_INOVIE_DEL;

  return (
    <>
      {!isInovie && location.pathname.includes("billing") && (
        <Button
          style={{ marginRight: 20 }}
          // Aller sur le site espace-client.cofidoc.fr
          onClick={() => window.open("https://espace-client.cofidoc.fr")}
        >
          Accéder à mon ancien espace
        </Button>
      )}

      <Button
        onClick={async () => {
          await app?.currentUser?.logOut();
          fetchSetCurrentOffice({ currentOffice: null, realmApp: null, application });
          navigate("/welcome");
        }}
      >
        Déconnexion
      </Button>
    </>
  );
}
