import { useAppContext } from "@/app/common/provider/AppProvider";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { Patient, Prescription, Seance, SeanceWithEntities, UserWithRoleInfo } from "@/types";
import { Box, Text } from "@/ui";
import { eachDayOfInterval } from "date-fns";
import { Dictionary, groupBy, uniqBy } from "lodash";
import { Fragment, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useThemeUI } from "theme-ui";
import {
  WrapperInfos,
  WrapperPraticiens,
  WrapperCare,
} from "../../../../../components/common/RightWrappers";
import { ModalDetailDay } from "../../../../../components/nurse/ModalDetailDay";
import { format } from "@/utils/format";
// icons
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import PixIcon from "@mui/icons-material/Pix";
import EditRoadIcon from "@mui/icons-material/EditRoad";
import PersonIcon from "@mui/icons-material/Person";
import WarningIcon from "@mui/icons-material/Warning";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { useQueryParams } from "@/utils/useQueryParams";
import { getRequestFilter } from "../../../../../utils/getRequestFilter";
// functions
function daysInMonth(year, month) {
  return new Date(year, month, 0)?.getDate();
}

function calendar({ start, end, seances }) {
  const interval = eachDayOfInterval({ start, end }).map((date) => {
    return {
      date,
      type: "inner",
      key: `${date.getMonth()} ${date.getFullYear()}`,
    };
  });
  // get the first date as index to add before first outer days
  const countDayToAddBeforeFirst = interval[0]?.date?.getDate();
  // count how many days needed to add
  const countDayToAddAfterLast =
    daysInMonth(
      interval[interval?.length - 1]?.date?.getFullYear(),
      interval[interval?.length - 1]?.date?.getMonth() + 1
    ) - interval[interval?.length - 1]?.date?.getDate();
  // get the last day as index to add after last outer days
  const lastDay = interval[interval?.length - 1];
  // add day to complet first month of interval
  for (let c = countDayToAddBeforeFirst - 1; c > 0; c--) {
    interval?.unshift({
      date: new Date(
        interval[0]?.date?.getFullYear(),
        interval[0]?.date?.getMonth(),
        c
      ),
      type: "outer",
      key: `${interval[0]?.date?.getMonth()} ${interval[0]?.date?.getFullYear()}`,
      // seances: undefined,
    });
  }
  // add day to complet last month of interval
  for (let i = 1; i <= countDayToAddAfterLast; i++) {
    interval?.push({
      date: new Date(
        lastDay?.date?.getFullYear(),
        lastDay?.date?.getMonth(),
        lastDay?.date?.getDate() + i
      ),
      type: "outer",
      key: `${lastDay?.date?.getMonth()} ${lastDay?.date?.getFullYear()}`,
      // seances: undefined,
    });
  }
  // group by month
  const grouped = groupBy(interval, (day) => day?.key);
  return grouped;
}

export default function PatientId() {
  //data states
  const {
    patient,
    praticiens,
    setNeedRefresh,
    needRefresh,
  }: {
    patient: Patient;
    praticiens: (UserWithRoleInfo & { color: string })[];
    setNeedRefresh: any;
    needRefresh: boolean;
  } = useOutletContext();
  const { client, user } = useMongoContext();
  const currentUser = user?.customData;
  const [seancePraticiens, setSeancePraticiens] = useState<
    (UserWithRoleInfo & { color: string })[]
  >([]);
  const [cares, setCares] = useState([]);
  const [seancesWithOtherBillingMode, setSeancesWithOtherBillingMode] =
    useState<SeanceWithEntities[]>([]);
  const [seancesWithOtherRelocation, setSeancesWithOtherRelocation] = useState<
    SeanceWithEntities[]
  >([]);
  const [seances, setSeances] = useState<SeanceWithEntities[]>([]);
  const [seancesGroupByDay, setSeancesGroupeByDay] =
    useState<Dictionary<SeanceWithEntities[]>>(undefined);
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);

  // ui/ux states
  const [selectedDay, setSelectedDay] = useState<{
    seances: SeanceWithEntities[];
    date: Date;
    patient: Patient;
  } | null>(null);
  const [openLateral, setOpenLateral] = useState<boolean>(false);
  const [openWrapper, setOpenWrapper] = useState<0 | 1 | 2 | 3>(0);
  const { theme } = useThemeUI();
  const { setLoading } = useAppContext();
  const params = useQueryParams();
  const { dbName } = useApplicationContext();

  function isPrescriptionIncomplete(seance: SeanceWithEntities): boolean {
    return seance.cares.some(care => {
      const prescription = prescriptions.find(p => p._id === care.prescription);
      return (!prescription?.media?._id || !prescription?.prescriptionDate) && !prescription?.empty;
    });
  }

  async function getData() {
    try {
      setLoading(true);

      const prescriptionsCollection = client
        .db(dbName)
        .collection("Prescription");
      const prescriptionsQuery: Prescription[] = await prescriptionsCollection.find({ deleted: false });
      setPrescriptions(prescriptionsQuery);

      const seancesCollection = client
        .db(dbName)
        .collection("Seance");
      const caresCollection = client
        .db(dbName)
        .collection("Care");
      const requestFilter = getRequestFilter(params, patient._id);
      let seancesFiltered: Seance[] = await seancesCollection.find(
        requestFilter
      );
      let caresFiltered = await caresCollection.find({
        _id: { $in: seancesFiltered.flatMap((c) => c.cares) },
        deleted: false,
      });
      const seancesProcessed = seancesFiltered.map(
        (seance) =>
        ({
          ...seance,
          cares: caresFiltered?.filter((c) => seance.cares?.includes(c._id)),
          patient: patient,
          deleted: false,
          key: format(seance?.plannedOn, "dd-MM"),
        } as SeanceWithEntities)
      );

      const seancesGrouped = groupBy(seancesProcessed, "key");

      const seancePraticientsUniq = uniqBy(
        seancesFiltered.flatMap((s) =>
          praticiens.find((c) => c.userId === s?.doneBy)
        ),
        "userId"
      )
        .filter(Boolean)
        .sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        );

      let mappedOtherBilling = seancesProcessed?.filter(
        (c) =>
          c?.billingMode !== patient?.billingMode &&
          c?.billingMode !== undefined
      );
      setSeancesWithOtherBillingMode(mappedOtherBilling);

      let mappedOtherRelocation = seancesProcessed?.filter(
        (c) =>
          c?.relocation?._id !== patient?.relocation?._id &&
          c?.relocation?._id !== undefined
      );
      setSeancesWithOtherRelocation(mappedOtherRelocation);

      setSeancePraticiens(seancePraticientsUniq);
      setSeancesGroupeByDay(seancesGrouped);
      setSeances(seancesProcessed);
      setCares(caresFiltered);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user && client && patient) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params.start,
    params.reload,
    params.end,
    params.status,
    params.collaborator,
    patient,
    user,
    client,
    needRefresh,
  ]);

  const interval = calendar({
    start: params?.start ? new Date(Number(params.start)) : new Date(),
    end: params?.end ? new Date(Number(params.end)) : new Date(),
    seances,
  });

  // USE EFFECT SET CARE / PRATICIEN / OTHER RELOCATION AND BILLING MODE
  useEffect(() => {
    if (user && client) {
      if (seances && patient) {
        let mappedOtherBilling = seances?.filter(
          (c) =>
            c?.billingMode !== patient?.billingMode &&
            c?.billingMode !== undefined
        );
        setSeancesWithOtherBillingMode(mappedOtherBilling);
      }
      if (seances && patient) {
        let mappedOtherRelocation = seances?.filter(
          (c) =>
            c?.relocation?._id !== patient?.relocation?._id &&
            c?.relocation?._id !== undefined
        );
        setSeancesWithOtherRelocation(mappedOtherRelocation);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seances, client, user, params?.start, params?.end, needRefresh]);

  return (
    <>
      <Box width="100%" height="100%">
        <Box
          flexDirection="column"
          width={openLateral ? "calc(80% - 20px)" : "calc(100% - 20px - 44px)"}
          style={{
            overflowY: "scroll",
            height: "100%",
            transition: "width ease 200ms",
          }}
        >
          {Object.keys(interval).map((keyName, keyIndex) => {
            return (
              <Box
                key={`${keyName}${keyIndex}`}
                flexDirection="column"
                width="100%"
              >
                <Box width="100%">
                  <Text fontWeight={300} fontSize={45} p={2}>
                    {format(interval[keyName][0].date, "MMMM yyyy")}
                  </Text>
                </Box>
                <Box flexWrap="wrap" width="100%">
                  {interval[keyName]?.map((item: any, index) => {
                    const groupeSeanceKey = format(item?.date, "dd-MM");
                    const currentDaySeance =
                      seancesGroupByDay?.[groupeSeanceKey];
                    return (
                      <Fragment key={`${item?.date}xx`}>
                        {index === 0 && (
                          <Box
                            width={`calc(100% /7 *${item.date.getDay() - 1})`}
                          />
                        )}
                        <Box height="120px" width="calc(100% /7)" p="5px">
                          <Box
                            className={
                              item?.type === "inner" ? "hoverDate" : ""
                            }
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            height="100%"
                            width="100%"
                            flexDirection="column"
                            bgcolor={
                              item?.type === "inner"
                                ? theme.colors.bgPale
                                : "#f9f9f9"
                            }
                            style={{
                              borderRadius: 5,
                              overflow: "hidden",
                              outline:
                                selectedDay?.date?.getTime() ===
                                  item?.date?.getTime()
                                  ? "solid #8c8cf8 2px"
                                  : "none",
                            }}
                          >
                            <Box
                              width="100%"
                              flex={0.2}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Text fontSize={11} fontWeight="500" p="4px">
                                {format(item.date, "iiii dd")}
                              </Text>
                            </Box>
                            {item?.type === "inner" && (
                              <Box
                                width="100%"
                                justifyContent="flex-start"
                                flex={0.8}
                                alignItems="flex-start"
                                flexDirection="column"
                                onClick={() =>
                                  setSelectedDay({
                                    seances: currentDaySeance,
                                    date: item.date,
                                    patient,
                                  })
                                }
                              >
                                {currentDaySeance?.map(
                                  (seance: SeanceWithEntities, index) => {
                                    return (
                                      <Fragment key={`${seance?._id}xx`}>
                                        {index > 0 && (
                                          <Box
                                            height="1px"
                                            width="100%"
                                            bgcolor="#fff"
                                          />
                                        )}

                                        <Box
                                          width="100%"
                                          height="25%"
                                          bgcolor={
                                            theme.colors.seanceStatus[
                                            seance.status
                                            ]
                                          }
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Box
                                            height="100%"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            {seance?.cares?.map(
                                              (care, index) => {
                                                return (
                                                  <Box
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    height="100%"
                                                    bgcolor="#ffffff6d"
                                                    key={`care${care?._id}`}
                                                  >
                                                    <Box
                                                      bgcolor="#fff"
                                                      width={15}
                                                      height={15}
                                                      borderRadius={50}
                                                      margin="2px"
                                                    >
                                                      <Box
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        bgcolor={`${care.category?.color}50`}
                                                        border={`1px solid ${care.category?.color}`}
                                                        style={{
                                                          borderRadius: 20,
                                                          minHeight: 15,
                                                          maxHeight: 15,
                                                          minWidth: 15,
                                                          maxWidth: 15,
                                                        }}
                                                      >
                                                        <Text
                                                          fontSize={15}
                                                          color={
                                                            care.category?.color
                                                          }
                                                          fontWeight={700}
                                                        >
                                                          +
                                                        </Text>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                );
                                              }
                                            )}
                                          </Box>
                                          {(seance?.status !== "to-do" || isPrescriptionIncomplete(seance)) && (
                                            <Box
                                              justifyContent="center"
                                              alignItems="center"
                                              height="100%"
                                            >
                                              {!!seance?.doneBy &&
                                                seance?.doneBy !==
                                                currentUser?._id && (
                                                  <Box
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    height="100%"
                                                    width="20px"
                                                    bgcolor="#ffffff6d"
                                                    style={{
                                                      position: "relative",
                                                    }}
                                                  >
                                                    {!seance?.replacing && (
                                                      <PersonIcon
                                                        style={{
                                                          color:
                                                            seancePraticiens?.find(
                                                              (c) =>
                                                                seance.doneBy ===
                                                                c.userId
                                                            )?.color,
                                                          fontSize: 18,
                                                        }}
                                                      />
                                                    )}
                                                    {!!seance?.replacing && (
                                                      <SupervisorAccountIcon
                                                        style={{
                                                          color:
                                                            seancePraticiens?.find(
                                                              (c) =>
                                                                seance.doneBy ===
                                                                c.userId
                                                            )?.color,
                                                          fontSize: 18,
                                                        }}
                                                      />
                                                    )}
                                                  </Box>
                                                )}
                                              {seance?.billingMode &&
                                                seance?.billingMode !==
                                                patient?.billingMode && (
                                                  <Box
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    height="100%"
                                                    bgcolor="#ffffff6d"
                                                  >
                                                    <PixIcon
                                                      style={{
                                                        color: "#ff0000",
                                                        fontSize: 17,
                                                      }}
                                                    />
                                                  </Box>
                                                )}
                                              {seance?.relocation?.label &&
                                                seance?.relocation?.label !==
                                                patient?.relocation
                                                  ?.label && (
                                                  <Box
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    height="100%"
                                                    bgcolor="#ffffff6d"
                                                  >
                                                    <EditRoadIcon
                                                      style={{
                                                        color: "#ff0000",
                                                        fontSize: 18,
                                                      }}
                                                    />
                                                  </Box>
                                                )}
                                              {isPrescriptionIncomplete(seance) && (
                                                <Box
                                                  justifyContent="center"
                                                  alignItems="center"
                                                  height="100%"
                                                  bgcolor="#ffffff6d"
                                                >
                                                  <WarningIcon
                                                    style={{
                                                      color: "#FFA500",
                                                      fontSize: "18px"
                                                    }}
                                                  />
                                                </Box>
                                              )}
                                            </Box>
                                          )}
                                        </Box>
                                      </Fragment>
                                    );
                                  }
                                )}
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Fragment>
                    );
                  })}
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box
          flexDirection="column"
          width={20}
          height="100%"
          justifyContent="flex-end"
          style={{ position: "relative", transition: "width ease 200ms" }}
        >
          <Box
            width={20}
            height={30}
            justifyContent="center"
            alignItems="center"
            style={{
              cursor: "pointer",
              backgroundColor: theme.colors.light as any,
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
            }}
            mb={1}
            onClick={() => {
              setOpenWrapper(0);
              setOpenLateral(!openLateral);
            }}
          >
            <Box
              style={{
                transform: !openLateral ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform ease 200ms",
              }}
            >
              <ChevronRightIcon />
            </Box>
          </Box>
        </Box>
        <Box
          display="block"
          width={openLateral ? "20%" : "44px"}
          height="100%"
          style={{
            position: "relative",
            transition: "width ease 200ms",
            overflow: "hidden",
            borderLeft: "solid 1px #f3f3f3",
          }}
        >
          <Box
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "solid 1px #f3f3f3",
            }}
            width="100%"
            height={20}
          >
            <Box
              width={35}
              height="100%"
              justifyContent="center"
              alignItems="center"
            />
            <Box
              height="100%"
              width="calc(100% - 44px)"
              style={{
                cursor: "pointer",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box width="100%" />
              <Box
                width={35}
                height={35}
                justifyContent="center"
                alignItems="center"
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setOpenLateral(false);
                  setOpenWrapper(0);
                }}
              >
                <CloseIcon style={{ color: "#181818", fontSize: 20 }} />
              </Box>
            </Box>
          </Box>
          <Box
            display="block"
            width="100%"
            height="calc(100% - 20px - 100px)"
            style={{ position: "relative" }}
          >
            <WrapperInfos
              setOpenWrapper={setOpenWrapper}
              openLateral={openLateral}
              setOpenLateral={setOpenLateral}
              openWrapper={openWrapper}
              index={1}
              infos={{
                patient,
                seancesWithOtherBillingMode,
                seancesWithOtherRelocation,
              }}
            />
            <WrapperPraticiens
              setOpenWrapper={setOpenWrapper}
              openLateral={openLateral}
              setOpenLateral={setOpenLateral}
              openWrapper={openWrapper}
              praticiens={seancePraticiens}
              index={2}
            />
            <WrapperCare
              setOpenWrapper={setOpenWrapper}
              openLateral={openLateral}
              setOpenLateral={setOpenLateral}
              openWrapper={openWrapper}
              cares={cares}
              index={3}
            />
          </Box>

          <Box
            height="100px"
            width="100%"
            flexDirection="column"
            style={{ borderTop: "solid 1px #f3f3f3" }}
          >
            <Box
              justifyContent="flex-start"
              alignItems="center"
              flexDirection="row"
              pt="2px"
            >
              <Box width="44px" justifyContent="flex-end" alignItems="center">
                <Text fontSize="12px" pr="2px">
                  {
                    seances?.filter((seance) => seance?.status === "to-do")
                      .length
                  }
                </Text>
                <Box
                  style={{
                    backgroundColor: theme.colors.seanceStatus["to-do"],
                    borderRadius: 50,
                    height: 13,
                    width: 13,
                  }}
                  mr={1}
                />
              </Box>
              {openLateral && (
                <Box width="calc(100% - 44px)">
                  <Text fontSize="12px">Séance à valider</Text>
                </Box>
              )}
            </Box>
            <Box
              justifyContent="flex-start"
              alignItems="center"
              flexDirection="row"
              pt="2px"
            >
              <Box width="44px" justifyContent="flex-end" alignItems="center">
                <Text fontSize="12px" pr="2px">
                  {
                    seances?.filter((seance) => seance?.status === "done")
                      .length
                  }
                </Text>
                <Box
                  style={{
                    backgroundColor: theme.colors.seanceStatus["done"],
                    borderRadius: 50,
                    height: 13,
                    width: 13,
                  }}
                  mr={1}
                />
              </Box>
              {openLateral && (
                <Box width="calc(100% - 44px)">
                  <Text fontSize="12px">Séance validée</Text>
                </Box>
              )}
            </Box>
            <Box
              justifyContent="flex-start"
              alignItems="center"
              flexDirection="row"
              pt="2px"
            >
              <Box width="44px" justifyContent="flex-end" alignItems="center">
                <Text fontSize="12px" pr="2px">
                  {
                    seances?.filter(
                      (seance) => seance?.status === "ready-to-bill"
                    ).length
                  }
                </Text>
                <Box
                  style={{
                    backgroundColor: theme.colors.seanceStatus["ready-to-bill"],
                    borderRadius: 50,
                    height: 13,
                    width: 13,
                  }}
                  mr={1}
                />
              </Box>
              {openLateral && (
                <Box width="calc(100% - 44px)">
                  <Text fontSize="12px">Séance envoyée</Text>
                </Box>
              )}
            </Box>
            <Box
              justifyContent="flex-start"
              alignItems="center"
              flexDirection="row"
              pt="2px"
            >
              <Box width="44px" justifyContent="flex-end" alignItems="center">
                <Text fontSize="12px" pr="2px">
                  {
                    seances?.filter(
                      (seance) => seance?.status === "put-on-hold"
                    ).length
                  }
                </Text>
                <Box
                  style={{
                    backgroundColor: theme.colors.seanceStatus["put-on-hold"],
                    borderRadius: 50,
                    height: 13,
                    width: 13,
                  }}
                  mr={1}
                />
              </Box>
              {openLateral && (
                <Box width="calc(100% - 44px)">
                  <Text fontSize="12px">Séance mise en attente</Text>
                </Box>
              )}
            </Box>
            {/* <Box justifyContent="flex-start" alignItems="center" flexDirection="row" pt="2px">
              <Box width="44px" justifyContent="flex-end" alignItems="center">
                <Text fontSize="12px" pr="2px">
                  {seances?.filter((seance) => seance?.status === "billed").length}
                </Text>
                <Box
                  style={{
                    backgroundColor: theme.colors.seanceStatus["billed"],
                    borderRadius: 50,
                    height: 13,
                    width: 13,
                  }}
                  mr={1}
                />
              </Box>
              {openLateral && (
                <Box width="calc(100% - 44px)">
                  <Text fontSize="12px">Séance facturée</Text>
                </Box>
              )}
            </Box> */}
          </Box>
        </Box>
      </Box>
      <Box position="fixed" width={0} height={0} top={0} left={0}>
        {selectedDay && (
          <ModalDetailDay
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            patient={patient}
            praticiens={praticiens}
            setNeedRefresh={setNeedRefresh}
          />
        )}
      </Box>
    </>
  );
}
