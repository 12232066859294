import { Formik, Form } from "formik";
import { Box, Text, Button, Link } from "../../../../ui";
import { Card } from "../../../../ui/Card";
import { TextField } from "../../../../ui/field/TextField";
import { loginCustomJwt } from "../../../../utils/helpers";
import { LAST_CONNECTION } from "@/app/nurse/App";
import { useNavigate } from "react-router-dom";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useAppContext } from "@/app/common/provider/AppProvider";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { setIdToken, setLocalStorageAccessToken, setRefreshToken } from "@/utils/localStorage";
import { useState } from "react";
import { useOfficeContext } from "@/app/common/provider/OfficeProvider";
import { fetchLogin } from "@/api/nurse/fetchLogin";
import { useThemeUI } from "theme-ui";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";

export function LoginNurse() {
  const { setUser, setClient, app } = useMongoContext();
  const { setLoading } = useAppContext();
  const { setUserOffices } = useOfficeContext();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const { theme } = useThemeUI();
  const { application } = useApplicationContext();
  const isInovie = process.env.REACT_APP_INOVIE_DEL;

  return (
    <Box height="100vh" justifyContent="center" alignItems="center" width="100vw" style={{ backgroundColor: "#f1f1f1" }}>
      <div className="background-cloud height40" />
      <div className="background-over" />
      {isInovie ? <div className="background-mountain-inovie" /> : <div className="background-mountain-cofidoc" />}
      <Box style={{ position: "relative", zIndex: 2 }}>
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={async (values) => {
            try {
              setLoading(true);
              setError(null);
              const result = await fetchLogin({
                email: values.email,
                password: values.password,
                realmApp: app,
                application,
              });
              localStorage.setItem(LAST_CONNECTION, new Date().toISOString());
              let user = await loginCustomJwt(result.idToken, app as any, isInovie ? "inovidel" : "mobility");
              setIdToken(result.idToken);
              setLocalStorageAccessToken(result.token);
              setRefreshToken(result.refreshToken);
              setUser(user);
              setUserOffices(result?.offices);
              setClient(app?.currentUser?.mongoClient("mongodb-atlas"));
              setLoading(false);
              navigate("/nurse/home");
              //automatique needed refresh to navigate
              navigate(0);
              return;
            } catch (e) {
              console.log("error", e);
              setError(e?.message);
            } finally {
              setLoading(false);
            }
          }}
        >
          <Form>
            <Card justifyContent="center" alignItems="center" display="flex" flexDirection="column" width="650px">
              <Box display="flex" alignItems="left" width="100%">
                <Link onClick={() => navigate("/welcome")}>
                  <ArrowBackIcon fontSize="small" />
                  <Box pr={1} />
                  <Text style={{ textAlign: "center", fontSize: 12 }}>Retour au portail de connexion</Text>
                </Link>
              </Box>
              <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                <Box display="flex" p={2} alignItems="center" justifyContent="center">
                  {isInovie ?
                    <div className="background-logo-inovidel" style={{ borderRadius: "10px", overflow: "hidden", height: "150px", width: "150px" }} />
                    :
                    <div className="background-logo-mobility" style={{ borderRadius: "10px", overflow: "hidden", height: "100px", width: "100px" }} />
                  }
                </Box>
                <Box style={{ margin: 20, textAlign: "center", fontWeight: 600 }}>
                  <Text style={{ textAlign: "center", fontWeight: 600 }}>
                    Bienvenue sur votre espace web&nbsp;
                    <span
                      style={{
                        fontWeight: 500,
                        color: theme.colors.primary as any,
                      }}
                    >
                      {isInovie ? "INOV'IDEL" : "Cofidoc Mobility"}
                    </span>
                  </Text>
                </Box>
                <Box flexDirection="column" width="100%">
                  <TextField label="Email" name="email" autofillBackgroundColor="highlight" />
                  <Box my={1} />
                  <TextField type="password" label="Mot de passe" name="password" autofillBackgroundColor="highlight" />
                  <Box justifyContent="center" alignItems="center" height={60}>
                    {error && (
                      <Box fontSize={11} color="#ff0000">
                        {error}
                      </Box>
                    )}
                  </Box>
                  <Box flex={1} justifyContent="center" mt={3}>
                    <Button type="submit">Connexion</Button>
                  </Box>
                  <Box justifyContent="center" alignItems="center" height={60}>
                    <Link onClick={() => navigate("/nurse/forgot-password")}>
                      <Text style={{ textAlign: "center", fontSize: 12 }}>Mot de passe oublié ?</Text>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Form>
        </Formik>
      </Box>
      <Box style={{ position: "absolute", bottom: 0, zIndex: 3, padding: 5 }}>
        <Text style={{ textAlign: "center", fontSize: 12 }}>© 2024 {!isInovie && "Cofidoc Groupe"}</Text>
      </Box>
    </Box>
  );
}
