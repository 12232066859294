import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useAppContext } from "@/app/common/provider/AppProvider";
import { useMongoContext } from "@/app/common/provider/MongoProvider";
import { useApplicationContext } from "@/app/common/provider/ApplicationContext";
import { Header, LeftNavBar } from "@/app/nurse/AppShell";
import { Refund, Invoice } from "@/types";
import { Box, Button, Text, Card, Accordion } from "@/ui";
import { useQueryParams } from "@/utils/useQueryParams";
import { getBlob, getDownloadURL } from "@/api/nurse/fetchDownloadURL";
import DownloadRefundPDF from "@/components/common/RefundPDF";
import { format, parseISO, isValid } from "date-fns";
import qs from "query-string";
import JSZip from "jszip";
//@ts-ignore
import { saveAs } from "file-saver";

// Icons
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// Material UI
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useThemeUI } from "theme-ui";

export default function BillingPage() {
  const params = useQueryParams();
  const { dbName, application } = useApplicationContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoading } = useAppContext();
  const { client, user } = useMongoContext();
  const currentUser = user?.customData;
  const [refunds, setRefunds] = useState<Refund[]>([]);
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const { theme } = useThemeUI();
  const isInovie = process.env.REACT_APP_INOVIE_DEL;

  // Get refunds function
  async function getFilteredRefunds() {
    const refundsCollection = client.db(dbName).collection("Refund");

    const refunds = await refundsCollection.find({
      adeli: currentUser?.adeli,
    });

    return refunds.sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  // Get invoices function
  async function getFilteredInvoices() {
    const invoicesCollection = client.db(dbName).collection("Invoice");

    const invoices = await invoicesCollection.find({
      adeli: currentUser?.adeli,
    });

    return invoices.sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  // Get data function
  async function getData() {
    try {
      const dateFilter = new Date(Number(params.period));
      setLoading(true);

      const refunds = await getFilteredRefunds();
      // Filter refunds for the selected month and for the selected year
      const filteredAndSortedRefunds = refunds.filter(
        (refund) => refund.date.getMonth() === dateFilter.getMonth() && refund.date.getFullYear() === dateFilter.getFullYear()
      );
      setRefunds(filteredAndSortedRefunds);

      const invoices = await getFilteredInvoices();
      // Filter invoices for the selected year
      const filteredAndSortedInvoices = invoices.filter((invoice) => invoice.date.getFullYear() === dateFilter.getFullYear());
      setInvoices(filteredAndSortedInvoices);
    } catch (error) {
      console.log("error :", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (user && client) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.period, user, client]);

  // Back to period function for navigation bar component
  const handleBackToPeriod = ({ period, type, order = "negative" }: { period: string; type: string; order: string }) => {
    let date = new Date(Number(period));
    if (type === "refunds") {
      if ((date.getMonth() === 11 && order === "positive") || (date.getMonth() === 0 && order === "negative")) {
        date.setFullYear(date.getFullYear() + (order === "negative" ? -1 : +1));
        date.setMonth(order === "negative" ? 11 : 0);
      } else {
        date.setMonth(date.getMonth() + (order === "negative" ? -1 : +1));
      }
    } else {
      date.setFullYear(date.getFullYear() + (order === "negative" ? -1 : +1));
    }
    let to = qs.stringify({ ...params, period: date.getTime() }, { arrayFormat: "index" });
    navigate(`${location.pathname}?${to}`, { replace: true });
  };

  // Navigation Bar Component
  const NavigationBar = ({ period, type = "refunds" }) => {
    return (
      <Card display="flex" justifyContent="space-between" alignItems="center" style={{ marginBottom: "16px" }}>
        <Button onClick={() => handleBackToPeriod({ period, type, order: "negative" })}>
          <ArrowBackIcon />
        </Button>
        <Typography variant="h6">
          {type === "refunds"
            ? firstLetterUppercase(format(new Date(Number(period)), "MMMM yyyy"))
            : "Année " + format(new Date(Number(period)), "yyyy")}
        </Typography>
        <Button
          disabled={
            (type === "refunds" && new Date(Number(period) + 1000 * 60 * 60 * 24 * 31) > new Date()) ||
            (type === "invoices" && new Date(Number(period) + 1000 * 60 * 60 * 24 * 365) > new Date())
          }
          onClick={() => handleBackToPeriod({ period, type, order: "positive" })}
        >
          <ArrowForwardIcon />
        </Button>
      </Card>
    );
  };

  // Format date function
  const formatDate = (dateStr: number | Date): string => {
    let date = dateStr;
    if (typeof dateStr === "string") {
      date = parseISO(dateStr);
    }
    return isValid(date) ? format(date, "dd/MM/yyyy") : "Date invalide";
  };

  // First letter in uppercase function
  const firstLetterUppercase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  // Add invoice to zip function
  const addInvoiceToZip = async (invoice: Invoice, zip?: JSZip) => {
    try {
      console.log("invoice :", invoice);
      const urlInvoice = await getDownloadURL({
        path: `${invoice.documentPath}`,
        application,
      });
      const pdfBlob = await getBlob(urlInvoice);
      const urlSplit = invoice.documentPath.split("/");
      const fileName = urlSplit[urlSplit.length - 1];
      zip?.file(fileName, pdfBlob, { binary: true });
    } catch (error) {
      console.log("error :", error);
    }
  };

  // Download invoices by month function
  const downloadInvoicesByMonth = async (invoice: Invoice) => {
    try {
      const urlInvoice = await getDownloadURL({
        path: `${invoice.documentPath}`,
        application,
      });
      const pdfBlob = await getBlob(urlInvoice);
      if (pdfBlob === undefined) {
        console.error("Error downloading invoice: pdfBlob is undefined");
        return;
      }
      const urlSplit = invoice.documentPath.split("/");
      const fileName = urlSplit[urlSplit.length - 1];
      saveAs(pdfBlob, fileName);
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };

  // Download invoices by year function
  const downloadInvoicesByYear = async () => {
    const zip = new JSZip();
    const downloadPromises = invoices.map((invoice) => addInvoiceToZip(invoice, zip));

    // Wait for all download promises to complete
    await Promise.all(downloadPromises);
    zip.generateAsync({ type: "blob" }).then(function (content) {
      // Download the zip file
      saveAs(content, `Factures ${format(new Date(Number(params.period)), "yyyy")}.zip`);
    });
  };

  // Pluralize function
  const pluralize = (count: number, singular: string, plural: string) => {
    return count === 1 ? singular : plural;
  };

  // Invoice presentation button component
  const renderDownloadButton = (invoice: Invoice) => (
    <Button onClick={() => downloadInvoicesByMonth(invoice)}>
      <DownloadIcon fontSize="small" style={{ color: theme.colors.primary as any }} />
      <Text m={0} style={{ marginLeft: "4px" }}>
        Télécharger le PDF
      </Text>
    </Button>
  );

  // Invoice presentation invoice item by month component
  const renderInvoiceByMonth = () =>
    invoices.map((invoice, index) => (
      <Box
        key={index}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        m={2}
        width="100%"
        borderBottom={`solid 1px ${theme.colors.light}`}
      >
        <Text fontSize={20}>
          {`${firstLetterUppercase(format(invoice.date, "MMMM"))} ${format(new Date(Number(params.period)), "yyyy").toUpperCase()}`}
        </Text>
        {renderDownloadButton(invoice)}
      </Box>
    ));

  return (
    <Box flexDirection="column" height="100vh" justifyContent="center" alignItems="center" width="100vw">
      {isInovie ? <div className="background-mountain-inovie" /> : <div className="background-mountain-cofidoc" />}
      <div className="background-cloud height40" />
      <div className="background-over" />
      <Header />
      <Box flexDirection="row" width="100%" height="calc(100% - 6vh)" justifyContent="center" alignItems="center">
        <LeftNavBar />
        <Box flexDirection="column" width="calc(100% - 10vw)" height="100%" justifyContent="center" alignItems="center" className="overBg">
          <Box
            flexDirection="column"
            width="98%"
            height="98%"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: "#fffffff6", borderRadius: 5 }}
          >
            {/* Filter */}
            <FilterPeriodBox />

            <Box
              style={{
                height: "calc(100% - 60px)",
              }}
              width="100%"
              justifyContent="center"
              alignItems="center"
              pt={4}
              pl={2}
              pr={2}
            >
              {/* Contenu de "Mes Remboursements" */}
              <Box
                width="60%"
                style={{
                  overflowY: "scroll",
                  paddingBottom: 50,
                }}
                pl={2}
                height="100%"
                flexDirection="column"
              >
                {/* Titre */}
                <Text fontWeight={300} fontSize={25} p={2} textAlign="left">
                  Mes remboursements
                </Text>

                {/* Barre de navigation */}
                <NavigationBar period={params.period} type="refunds" />

                {/* Contenu de "Mes Remboursements" */}

                {refunds.length > 0 ? (
                  <>
                    <Card flexDirection="column" p={2} display="flex" style={{ marginBottom: "16px" }}>
                      {/* Présentation montant et informations */}
                      <Box flexDirection="row" justifyContent="space-evenly" alignItems="center" p={3} m={2}>
                        <Box justifyContent="center" alignItems="center" display="flex" flexDirection="column" flexShrink={0} p={2} m={2}>
                          <Text fontSize={26} fontWeight={800}>
                            {refunds.reduce((acc, refund) => {
                              const total = refund?.total ? parseFloat(refund.total) : 0;
                              return acc + total;
                            }, 0)}{" "}
                            €
                          </Text>
                          <Text fontSize={16}>
                            {refunds.length} {pluralize(refunds.length, "virement", "virements")} -{" "}
                            {refunds.filter((refund, index, self) => self.findIndex((r) => r.nomOrganisme === refund.nomOrganisme) === index).length}{" "}
                            {pluralize(
                              refunds.filter((refund, index, self) => self.findIndex((r) => r.nomOrganisme === refund.nomOrganisme) === index).length,
                              "organisme",
                              "organismes"
                            )}
                          </Text>
                        </Box>

                        {/* Bouton pour télécharger */}
                        <DownloadRefundPDF data={refunds} period={params.period} currentUser={currentUser} />
                      </Box>
                    </Card>

                    {/* Accordéon pour les détails des remboursements */}
                    <Card flexDirection="column" p={2} display="flex">
                      {refunds.map((refund, index) => (
                        <Accordion
                          key={refund._id || index}
                          title={`${formatDate(refund.date)} - ${refund.nomOrganisme} - ${parseFloat(refund.total).toFixed(2)} €`}
                        >
                          <Table size="small" style={{ border: "1px solid #e0e0e0" }}>
                            <TableHead>
                              <TableRow>
                                <TableCell>Facture</TableCell>
                                <TableCell>Patient</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Payé</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {refund.details.map((detail, detailIndex) => (
                                <TableRow
                                  key={detailIndex}
                                  style={{
                                    backgroundColor: detailIndex % 2 === 0 ? "#fff" : "#f3e7e765",
                                  }}
                                >
                                  <TableCell>{detail.numFacture}</TableCell>
                                  <TableCell>{detail.nomPatient}</TableCell>
                                  <TableCell>
                                    {formatDate(detail.debut)} - {formatDate(detail.fin)}
                                  </TableCell>
                                  <TableCell>{parseFloat(detail.paye).toFixed(2)} €</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Accordion>
                      ))}
                    </Card>
                  </>
                ) : (
                  <Card flexDirection="column" p={2} display="flex">
                    <Box justifyContent="center" alignItems="center" width="100%" height="100%" p={2} m={2}>
                      <Text fontSize={18} fontWeight={500}>
                        Aucun remboursement pour cette période
                      </Text>
                    </Box>
                  </Card>
                )}
              </Box>

              {/* Contenu de "Mes Factures" */}
              <Box
                width="40%"
                style={{
                  overflowY: "scroll",
                }}
                pl={2}
                height="100%"
                flexDirection="column"
              >
                {/* Titre */}
                <Text fontWeight={300} fontSize={25} p={2} textAlign="left">
                  Mes factures
                </Text>

                {/* Barre de navigation */}
                <NavigationBar period={params.period} type="invoices" />

                {/* Contenu de "Mes Factures" */}
                <>
                  {/* Si l'année est supérieure à l'année actuelle ou si il n'y a pas de factures, on affiche un message */}
                  {new Date(Number(params.period)) > new Date() || invoices.length === 0 ? (
                    <Card flexDirection="column" p={2}>
                      <Box justifyContent="center" alignItems="center" width="100%" height="100%">
                        <Text fontSize={18} fontWeight={500}>
                          Aucune facture pour cette période
                        </Text>
                      </Box>
                    </Card>
                  ) : (
                    <>
                      <Card flexDirection="column" p={2} style={{ marginBottom: "16px" }}>
                        {/* Présentation par année */}
                        <Box flexDirection="row" justifyContent="space-between" alignItems="center" p={2} m={2} width="100%">
                          <Text fontSize={20}>{`ANNÉE ${format(new Date(Number(params.period)), "yyyy").toUpperCase()}`}</Text>
                          <Button onClick={() => downloadInvoicesByYear()}>
                            <DownloadIcon fontSize="small" style={{ color: theme.colors.primary as any }} />
                            <Text m={0} style={{ marginLeft: "4px" }}>
                              Télécharger le PDF
                            </Text>
                          </Button>
                        </Box>
                      </Card>
                      <Card flexDirection="column" p={2}>
                        {/* Présentation par mois */}
                        {renderInvoiceByMonth()}
                      </Card>
                    </>
                  )}
                </>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const FilterPeriodBox = () => {
  const params = useQueryParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [periodVisible, setPeriodVisible] = useState(false);
  const { theme } = useThemeUI();
  return (
    <Box justifyContent="center" alignItems="center" width="100%" height="60px" position="relative">
      <Box
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="100%"
        position="relative"
        borderBottom={`solid 1px ${theme.colors.light}`}
      >
        <Box justifyContent="center" alignItems="center">
          <Text fontWeight={300} fontSize={26} pl={3}>
            Facturation
          </Text>
        </Box>
        <Box justifyContent="center" alignItems="flex-end" pb={1} pr={3}>
          {/* FILTER PERIODE */}
          <Box style={{ position: "relative" }} flexDirection="column">
            <Text fontSize={12}>Période du :</Text>
            <Box
              p="2px"
              pl={1}
              pr={1}
              style={{
                borderRadius: 5,
                backgroundColor: "#fff",
                border: "solid 1px",
                borderColor: "#c7c7c7",
                cursor: "pointer",
                fontSize: 13,
                fontWeight: 600,
                maxHeight: "28px",
                minHeight: "28px",
                color: "#454545",
                width: 100,
              }}
              alignItems="center"
              onClick={() => {
                setPeriodVisible(!periodVisible);
              }}
            >
              {format(new Date(Number(params.period)), "MM/yyyy")}
            </Box>

            {periodVisible && (
              <Box
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "#fff",
                  boxShadow: "0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)",
                  borderRadius: "5px",
                  overflow: "hidden",
                  zIndex: 99999,
                }}
              >
                <Calendar
                  maxDetail="year"
                  onChange={(c) => {
                    const period = new Date(c);
                    let to = qs.stringify({ ...params, period: period.getTime() }, { arrayFormat: "index" });
                    navigate(`${location.pathname}?${to}`, {
                      replace: true,
                    });
                    setPeriodVisible(false);
                  }}
                  maxDate={new Date()}
                  value={new Date(Number(params.period))}
                />
                <Box p={1}>
                  <Button onClick={() => setPeriodVisible(false)}>Fermer</Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
