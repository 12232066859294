import { Header, LeftNavBar } from "@/app/nurse/AppShell";
import { Box } from "@/ui";
import { Outlet } from "react-router-dom";

export default function OfficeId() {
  const isInovie = process.env.REACT_APP_INOVIE_DEL;
  
  return (
    <Box flexDirection="column" height="100vh" justifyContent="center" alignItems="center" width="100vw">
      {isInovie ? <div className="background-mountain-inovie" /> : <div className="background-mountain-cofidoc" />}
      <div className="background-cloud height40" />
      <div className="background-over" />
      <Header />
      <Box flexDirection="row" width="100%" height="calc(100% - 6vh)" justifyContent="center" alignItems="center">
        <LeftNavBar />
        <Box flexDirection="column" width="calc(100% - 10vw)" height="100%" justifyContent="center" alignItems="center" className="overBg">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
