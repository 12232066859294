import { useState, useEffect } from "react";
import * as Realm from "realm-web";
import { Toaster } from "react-hot-toast";
import setDefaultOptions from "date-fns/setDefaultOptions";
import { fr } from "date-fns/locale";
import { PageLoader } from "../common/PageLoader";
import { AppRouter } from "../Router";
import { MongoProvider } from "../common/provider/MongoProvider";
import { AppProvider } from "../common/provider/AppProvider";
import { UserClientProvider } from "../common/provider/UserClientProvider";
import { useApplicationContext } from "../common/provider/ApplicationContext";

setDefaultOptions({ locale: fr });
export const LAST_CONNECTION = "LAST_CONNECTION";

export default function AppContent() {
  const { application } = useApplicationContext();
  const [periodeStart, setPeriodeStart] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [periodeEnd, setPeriodeEnd] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)));
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState<any>(null);
  const [user, setUser] = useState<Realm.User>(null);
  const [app, setApp] = useState<Realm.App>(new Realm.App({ id: process.env.REACT_APP_REALM_APP_ID_TAXI || "" }));

  useEffect(() => {
    if (user?.identities) {
      console.log("=>  user:", user?.identities);
    }
  }, [user]);

  return (
    <MongoProvider value={{ app, client, user, setClient, setUser, setApp }}>
      <UserClientProvider>
        <AppProvider
          value={{
            setLoading,
            loading,
            periodeStart,
            periodeEnd,
            setPeriodeStart,
            setPeriodeEnd,
          }}
        >
          {loading && <PageLoader />}
          <Toaster
            position="top-right"
            containerStyle={{
              top: 35,
              left: 20,
              bottom: 20,
              right: 50,
              borderRadius: 5,
            }}
            toastOptions={{
              className: "",
              style: {
                background: "white",
                color: "#fff",
                fontWeight: 600,
              },
              success: {
                style: {
                  background: "#a1df89",
                },
              },
              error: {
                style: {
                  background: "#e67b7b",
                },
              },
              icon: null,
            }}
          />
          <AppRouter application={application} />
        </AppProvider>
      </UserClientProvider>
    </MongoProvider>
  );
}
