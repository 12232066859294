import { useNavigate } from "react-router-dom";
import { useMongoContext } from "./provider/MongoProvider";

export function PrivateRoute({ Component }) {
  const navigate = useNavigate();
  const { app } = useMongoContext();
  console.log("=> isLoggedIn:", app?.currentUser?.isLoggedIn);

  if (!app?.currentUser?.isLoggedIn) {
    navigate("/welcome");
  }

  return <Component />;
}
