import { fetchAPI } from "@/utils/fetchAPI";

type FetchLogin = { email: string; password: string; realmApp: any };

export async function fetchLogin({ email, password, realmApp }: FetchLogin) {
  const res = await fetchAPI({
    url: "/taxi/login",
    options: {
      method: "POST",
      body: JSON.stringify({ email, password }),
    },
    realmApp,
    application: "taxi",
  });
  console.log(" res:", res);
  return res;
}
