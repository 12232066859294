import { OfficeWithRole } from "@/types";
import { createContext, useContext } from "react";

type OfficeContextType = {
  currentOfficeInfo: OfficeWithRole | null;
  setCurrentOfficeInfo: (office: OfficeWithRole | null) => void;
  userOffices: OfficeWithRole[];
  setUserOffices: (offices: OfficeWithRole[] | null) => void;
  setCurrentOffice: (office: OfficeWithRole | null) => Promise<any>;
};

const officeContext = createContext<OfficeContextType>({} as OfficeContextType);

export const OfficeProvider = officeContext.Provider;

export function useOfficeContext() {
  const officecontext = useContext(officeContext);
  return officecontext;
}
