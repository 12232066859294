import { ApplicationType } from "@/types";
import { fetchAPI } from "@/utils/fetchAPI";

type FetchForgotPassword = { email: string; realmApp: any; application: ApplicationType };
type FetchResetPassword = { email: string; code: string; password: string; realmApp: any; application: ApplicationType };

export async function fetchForgotPassword({ email, realmApp, application }: FetchForgotPassword) {
  try {
    const res = await fetchAPI({
      url: `/${application}/auth/forgotPassword`,
      options: {
        method: "POST",
        body: JSON.stringify({ email }),
      },
      realmApp,
      application,
    });
    console.log(" res:", res);
    return res;
  } catch (error) {
    throw new Error();
  }
}

export async function fetchResetPassword({ email, code, password, realmApp, application }: FetchResetPassword) {
  try {
    const res = await fetchAPI({
      url: `/${application}/auth/changePassword`,
      options: {
        method: "POST",
        body: JSON.stringify({ email, code, password }),
      },
      realmApp,
      application,
    });
    console.log(" res:", res);
    return res;
  } catch (error) {
    throw new Error();
  }
}
